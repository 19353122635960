import React, { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import "./Table.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { VscCopy } from "react-icons/vsc";

const PTable = (props) => {
  const thRef = useRef([]);

  const { columns, data, onRowClick, collapsableContent, onClick } = props;

  const [collapsedRows, setCollapsedRows] = useState(
    data.reduce((acc, cur) => ({ ...acc, [cur.id]: false }), {})
  );

  useEffect(() => {
    const resizeHandler = () => {
      thRef.current.forEach((th, i) => {
        const td = document.querySelector(
          `table tr:first-child td:nth-child(${i + 1})`
        );
        if (td) {
          th.style.width = `${td.clientWidth}px`;
        }
      });
    };

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [columns, data]);

  const toggleRow = (id) => {
    setCollapsedRows((prevState) => {
      const allCollapsed = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      return {
        ...allCollapsed,
        [id]: !prevState[id],
      };
    });

    onRowClick && onRowClick(id);
  };
  return (
    <>
      <table className="table-header" style={{ width: "100%" }}>
        <tr>
          {props.columns.map(({ label, accessor }, i) => {
            return (
              <th
                key={accessor}
                style={{
                  textAlign: "center",
                  minWidth: accessor === "collapse" ? "50px" : "150px",
                  maxWidth: "400px",
                }}
                ref={(el) => (thRef.current[i] = el)}
              >
                {label}
              </th>
            );
          })}
        </tr>
      </table>
      <Table
        bordered
        className="table-body"
        style={{
          borderCollapse: "separate",
          borderSpacing: "0px 10px",
          padding: "0px",
        }}
      >
        {props.data.map((data) => {
          return (
            <>
              <tr
                key={data.id}
                onClick={() => toggleRow(data.id)}
                style={{ border: "1px solid #CEE6FF", cursor: "pointer" }}
              >
                {props.columns.map(({ accessor }) => {
                  const tData = data[accessor] ? data[accessor] : "——";
                  return accessor === "collapse" ? (
                    <>
                      {props.collapsable && (
                        <td
                          key={accessor}
                          className="table-column"
                          style={{
                            textAlign: "center",
                            minWidth: "50",
                            maxWidth: "300px",
                          }}
                        >
                          <a href="#" title="voir plus des détails">
                            {collapsedRows[data.id] ? (
                              <IoIosArrowUp color="#CEE6FF"></IoIosArrowUp>
                            ) : (
                              <IoIosArrowDown color="#CEE6FF"></IoIosArrowDown>
                            )}
                          </a>
                        </td>
                      )}
                    </>
                  ) : (
                    <td
                      key={accessor}
                      className="table-column"
                      style={{
                        textAlign: "center",
                        minWidth: "150px",
                        maxWidth: "200px",
                      }}
                    >
                      {tData}
                    </td>
                  );
                })}
              </tr>
              {props.collapsable && collapsedRows[data.id] && (
                <tr style={{ backgroundColor: "#F2F2F2" }}>
                  <td className="table-row mt-5" colSpan={props.columns.length}>
                    {collapsableContent(data.id, data.toggleFields)}
                  </td>
                </tr>
              )}
            </>
          );
        })}
      </Table>
    </>
  );
};

export default PTable;
