import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateActiveTab } from './checkoutBuilderSlice';
import { getAllRoles } from '../../services/api/panormix';

export const fetchRoleList = createAsyncThunk('roles/fetchRoleList', async () => {
    const response = await getAllRoles(12);
    return response.data.roles;
});

const manageSettingSlice = createSlice({

    name: 'manageSetting',
    initialState: {
        listRoleUpdated: false,
        actualTab: "1",
        showUserForm: false,
        showRoleForm: false,
        comingFromUserForm: false,
        listRole: []
    },
    reducers: {
        toggleShowUserForm: (state, action) => {
            state.showUserForm = action.payload
        },
        toggleShowRoleForm: (state, action) => {
            state.showRoleForm = action.payload
        },
        toggleComingFromUser: (state, action) => {
            state.comingFromUserForm = action.payload
        },
        toggleListRoleUpdated: (state, action) => {
            state.listRoleUpdated = action.payload
        },
        updateActualTab: (state, action) => {
            state.actualTab = action.payload
        },

        setListRoles: (state, action) => {
            state.listRole = action.payload;
        },
        addRoleToList: (state, action) => {
            state.listRole.push(action.payload);
        },
        removeRoleFromList: (state, action) => {
            state.listRole = state.listRole.filter(role => role.id !== action.payload);
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleList.fulfilled, (state, action) => {
                state.listRole = action.payload;
            });

    },
});

export const { toggleListRoleUpdated, updateActualTab, toggleShowUserForm, toggleShowRoleForm, toggleComingFromUser } = manageSettingSlice.actions;

export default manageSettingSlice.reducer;

