// formBuilderSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllProductsByType, getAllDeliveryOptions } from '../../services/api/panormix';
import { formatDate } from '../../utils/helper';

export const fetchProductList = createAsyncThunk('products/getList', async (_, { getState }) => {
    const state = getState();
    const checkoutType = state.checkoutBuilder.checkoutType;
    const response = await getAllProductsByType(checkoutType);
    return response.data.products;
});

export const fetchDeliveryOptions = createAsyncThunk('deliveryOptions/getList', async () => {
    const response = await getAllDeliveryOptions();
    return response.data.delivery_options;
});

const convertToDateString = (datetimeString) => {
    // Parse the datetime string into a Date object
    const date = new Date(datetimeString);

    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date as "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
};


const initState = {
    editMode: false,
    paylinkId: "",
    checkoutType: "",
    activeTab: "1",
    eventSingleMoreChoice: 'one',
    afterPayment: false,
    optionalFields: [],
    availableProducts: [],
    selectedProducts: [],
    availableDeliveryOptions: [],
    selectedDeliveryOption: null,
    extraEventFields: [],
    showExtraFieldQuantity: false,
    extraFieldErrors: [],
    photoDonation: null,
    photoEvent: null,
    photoEdited: false,

    paylinkDetails: {
        checkout_title: "",
        checkout_description: "",
        checkout_custom_returnmsg: "",
        checkout_redirect_url: "",
        checkout_donation_amount: "",
        checkout_image: null,
        checkout_is_limited: false,
        checkout_min_donation: 0,
        checkout_max_donation: 1000,
        checkout_donation_goal: 1000,
        checkout_price: 0,
        checkout_expiry_date: "",
        checkout_event_stock: 0
    },
    payerInfos: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        commentary: ""
    },

    checkoutFormErrors: {
        title: "",
        description: "",
        products: false,
        optionalFields: false,
        custom_return_msg: "",
        redirect_url: "",
        price: "",
        expiry_date: ""
    },

    temporaryEventProduct: {
        picture: null,
        label: "",
        description: "",
        price: "",
        qty: "",
        options: [],
    },
    resultedAmount: 0,

    checkoutOrder: {
        resultedAmount: 0,
        products: [],
        delivery: null,
        extras: []
    },
}

const checkoutBuilderSlice = createSlice({
    name: 'checkoutBuilder',
    initialState: initState,
    reducers: {

        calculateTotalAmountProduct(state) {
            let totalAmount = state.checkoutOrder.products.reduce((total, checkoutProduct) => {

                const fullProduct = state.selectedProducts.find(p => p.id === checkoutProduct.product_id);

                if (fullProduct) {
                    let productTotal = fullProduct.init_price * checkoutProduct.quantity;
                    let optionsTotal = 0;

                    if (Array.isArray(checkoutProduct.options) && checkoutProduct.options.length > 0) {
                        JSON.parse(JSON.stringify(checkoutProduct.options)).map((option, index) => {
                            if (state.paylinkType == "event") {
                                optionsTotal += parseInt(option.price) * option.quantity;
                            } else {
                                optionsTotal += parseInt(option.price) * checkoutProduct.quantity;
                            }
                        })
                    }

                    productTotal += optionsTotal;
                    total += productTotal;
                }

                return total;
            }, 0);

            if (state.checkoutOrder.delivery) totalAmount += parseInt(state.selectedDeliveryOption.options[0].option_values.find(ov => ov.id == parseInt(state.checkoutOrder.delivery)).price);
            state.checkoutOrder.resultedAmount = totalAmount;
        },

        calculateTotalAmountEvent(state) {
            const productTotal = state.checkoutOrder.products.reduce((total, event) => {
                return total + (event.price * event.quantity);
            }, 0);

            const extrasTotal = state.checkoutOrder.extras.reduce((total, extra) => {
                return total + (extra.price * extra.quantity);
            }, 0);

            state.checkoutOrder.resultedAmount = productTotal + extrasTotal;
        },

        calculatedCheckoutProductPrice: (state, product_index) => {
            const checkoutProd = state.checkoutOrder.products[product_index];

            if (checkoutProd) {
                const { product_id, quantity, options, price } = checkoutProd;
                const product = state.selectedProducts.find(prod => prod.id == product_id);
                if (product) {
                    let productPrice = product.init_price;

                    options.forEach(opt => {
                        const productOption = product.options.find(option => option.id === opt.option_id);

                        if (productOption) {
                            const optionValue = productOption.option_values.find(val => val.id === opt.option_value_id);

                            if (optionValue) {
                                productPrice += parseFloat(optionValue.price);
                            }
                        }
                    });

                    checkoutProd.price = productPrice;
                }

            }
        },

        switchToEditMode: (state) => {
            state.editMode = true
        },

        updatePaylinkId: (state, action) => {
            state.paylinkId = action.payload
        },

        updateChekoutType: (state, action) => {
            state.checkoutType = action.payload;
        },

        updateActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },

        setAfterPayment: (state) => {
            state.afterPayment = !state.afterPayment;
        },

        addOptionalField: (state) => {
            const newField = {
                label: '',
                description: '',
                type: 'text',
                required: false,
            };
            state.optionalFields.push(newField);
        },

        removeOptionalField: (state, action) => {
            state.optionalFields = state.optionalFields.filter((field, index) => index !== action.payload);
        },

        updateOptionalField: (state, action) => {
            console.log(action.payload)
            const { index, field } = action.payload;
            state.optionalFields[index] = field;
        },

        addProduct: (state, action) => {
            state.selectedProducts.push(action.payload);
            let added_product = action.payload;
            const base_price = added_product.init_price;
            const options_array = [];
            let productPrice = 0;

            if (added_product.options.length > 0) {
                added_product.options.forEach(option => {
                    let ov_default = option.option_values[0];
                    productPrice += parseFloat(ov_default.price);
                    options_array.push({ option_id: parseInt(option.id), option_value_id: parseInt(ov_default.id), price: parseFloat(ov_default.price) })
                })
            }

            productPrice += base_price;

            state.checkoutOrder.products.push({
                product_id: action.payload.id,
                quantity: 0,
                options: options_array,
                price: productPrice
            });
            state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        selectEventProduct: (state, action) => {
            if (state.selectedProducts.length > 0) {
                state.availableProducts = state.availableProducts.filter(product => product.id !== action.payload.id);
                state.availableProducts = [...state.availableProducts, ...state.selectedProducts]
                state.selectedProducts = [];
                state.selectedProducts.push(action.payload);
            } else {
                state.availableProducts = state.availableProducts.filter(product => product.id !== action.payload.id);
                state.selectedProducts.push(action.payload);
            }

            state.availableProducts[0].options[0].option_values.map(event => {
                state.checkoutOrder.products.push({
                    event_id: event.id,
                    quantity: 0,
                    price: event.price
                });
            })
            if (state.availableProducts[0].options.length > 1) {
                state.availableProducts[0].options[1].option_values.map(extra => {
                    state.checkoutOrder.extras.push({
                        extra_id: extra.id,
                        quantity: 0,
                        price: extra.price
                    });
                })
            }

        },

        addProductToCheckoutOrder: (state, action) => {
            const { p_id, p_index } = action.payload;
            let added_product = state.selectedProducts.find(p => p.id === p_id);
            let checkout_product = state.checkoutOrder.products[p_index];
            const base_price = added_product.init_price;
            let productPrice = checkout_product.price;

            state.checkoutOrder.products.push({
                product_id: p_id,
                quantity: 0,
                options: checkout_product.options,
                price: productPrice
            });

            state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        removeProductFromCheckoutOrder: (state, action) => {
            const { p_id, index } = action.payload;

            let productExist = state.checkoutOrder.products.find(p => p.product_id == p_id);

            if (productExist) {
                state.checkoutOrder.products.splice(index, 1);
            }
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        removeProduct: (state, action) => {
            state.selectedProducts = state.selectedProducts.filter((product) => product.id !== action.payload);
            state.checkoutOrder.products = state.checkoutOrder.products.filter(
                (product) => product.product_id !== action.payload
            );
            state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        resetSelectedProducts: (state) => {
            state.availableProducts = [...state.availableProducts, ...state.selectedProducts];
            state.selectedProducts = [];
            state.checkoutOrder.products = [];
            state.checkoutOrder.resultedAmount = 0;
        },

        addAvailableProduct: (state, action) => {
            if (!state.availableProducts.some(product => product.id === action.payload.id)) {
                state.availableProducts.push(action.payload);
            }
        },

        removeAvailableProduct: (state, action) => {
            state.availableProducts = state.availableProducts.filter(product => product.id !== action.payload);
        },

        updatePaylinkDetails: (state, action) => {
            state.paylinkDetails = { ...state.paylinkDetails, ...action.payload };
        },

        setSelectedDeliveryOption: (state, action) => {
            state.selectedDeliveryOption = action.payload
            if (!state.selectedDeliveryOption) state.checkoutOrder.delivery = null;
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        setCheckoutFormErrors: (state, action) => {
            const { key, value } = action.payload;

            if (key in state.checkoutFormErrors) {
                state.checkoutFormErrors[key] = value;
            }
        },

        setEventSingleMoreChoice: (state, action) => {
            state.eventSingleMoreChoice = action.payload;
        },

        addExtraEventField: (state) => {
            state.extraEventFields.push({ label: '', description: '', prix: '', qty: '' });
            state.extraFieldErrors.push({ label: '', description: '', price: '', qty: '' });
        },

        updateExtraEventField: (state, action) => {
            const { index, field, value } = action.payload;
            state.extraEventFields[index][field] = value;
        },

        removeExtraEventField: (state, action) => {
            state.extraEventFields = state.extraEventFields.filter((field, index) => index !== action.payload);
        },

        toggleShowExtraEventQuantity: (state) => {
            state.showExtraFieldQuantity = !state.showExtraFieldQuantity;
        },

        setExtraFieldError: (state, action) => {
            const { index, field, error } = action.payload;
            if (!state.extraFieldErrors[index]) {
                state.extraFieldErrors[index] = {};
            }
            state.extraFieldErrors[index][field] = error;
        },

        clearExtraFieldError: (state, action) => {
            const { index, field } = action.payload;
            if (state.extraFieldErrors[index]) {
                state.extraFieldErrors[index][field] = '';
            }
        },

        updateResultedAmount: (state, action) => {
            state.resultedAmount = action.payload;
        },

        updateProductQuantity: (state, action) => {
            const { productIndex, quantity } = action.payload;
            if (productIndex >= 0 && productIndex < state.checkoutOrder.products.length) {
                state.checkoutOrder.products[productIndex].quantity = quantity;
            }
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        updateEventQuantity: (state, action) => {
            const { ov_id, qty } = action.payload;

            const updatedEvent = state.checkoutOrder.products.find(event => event.event_id == ov_id)
            if (updatedEvent) {
                updatedEvent.quantity = qty
            }

            checkoutBuilderSlice.caseReducers.calculateTotalAmountEvent(state);
        },

        updateExtraQuantity: (state, action) => {
            const { ov_id, qty } = action.payload;

            const updatedExtra = state.checkoutOrder.extras.find(extra => extra.extra_id == ov_id)

            if (updatedExtra) {
                updatedExtra.quantity = qty
            }

            checkoutBuilderSlice.caseReducers.calculateTotalAmountEvent(state);

        },

        updateOptionQuantity: (state, action) => {
            const { p_id, ov_id, qty } = action.payload;

            const product = state.selectedProducts.find(p => p.id === p_id);
            if (product) {
                product.options.forEach(option => {
                    const optionValue = option.option_values.find(ov => ov.id === ov_id);
                    if (optionValue) {
                        const checkoutProduct = state.checkoutOrder.products.find(cp => cp.product_id === p_id);
                        const checkoutOption = checkoutProduct.options.find(co => co.option_id === ov_id);

                        if (qty > 0) {
                            if (checkoutOption) {
                                checkoutOption.quantity = qty;
                            } else {
                                checkoutProduct.options.push({ option_id: ov_id, quantity: qty, price: optionValue.price });
                            }
                        } else {
                            checkoutProduct.options = checkoutProduct.options.filter(co => co.option_id !== ov_id);
                        }

                    }
                });
            }

            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        updateOptionValue: (state, action) => {
            const { p_index, p_id, o_id, ov_id, qty } = action.payload;
            const checkoutProduct = state.checkoutOrder.products[p_index];
            const product = state.selectedProducts.find(p => p.id === p_id);

            if (ov_id === "") {
                const index = checkoutProduct.options.findIndex(option => option.option_id === o_id);
                if (index !== -1) {
                    checkoutProduct.options.splice(index, 1);
                    checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
                    checkoutBuilderSlice.caseReducers.calculatedCheckoutProductPrice(state, p_index);
                }
            } else {
                product.options.forEach(option => {
                    const optionValue = option.option_values.find(ov => ov.id === parseInt(ov_id));
                    if (optionValue) {
                        let optionExist = checkoutProduct.options.find(op => op.option_id == parseInt(o_id))

                        if (optionExist) {
                            optionExist.option_value_id = parseInt(ov_id);
                            //optionExist.quantity = qty;
                            optionExist.price = optionValue.price;
                        } else {
                            checkoutProduct.options.push({ option_id: parseInt(option.id), option_value_id: parseInt(ov_id), price: optionValue.price });
                        }

                        checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
                        checkoutBuilderSlice.caseReducers.calculatedCheckoutProductPrice(state, p_index);
                    }
                });
            }
        },

        savePhotoDonation(state, action) {
            state.photoDonation = action.payload;
        },

        savePhotoEvent(state, action) {
            state.photoEvent = action.payload;
        },

        chooseDeliveryOption: (state, action) => {
            state.checkoutOrder.delivery = action.payload;
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        fillTemporaryEventProduct: (state, action) => {
            state.temporaryEventProduct = { ...state.temporaryEventProduct, ...action.payload };
        },

        fillPaylinkEditMode: (state, action) => {

            const { checkout_type, title, description, products, additional_fields, picture, range_min, range_max, donation_goal, expiry_date } = action.payload
            state.paylinkDetails = { ...state.paylinkDetails, ...{ checkout_title: title, checkout_description: description, checkout_min_donation: range_min, checkout_max_donation: range_max, checkout_donation_goal: donation_goal } };

            //reset selected prod
            state.availableProducts = [...state.availableProducts, ...state.selectedProducts];
            state.selectedProducts = [];
            state.checkoutOrder.products = [];
            state.optionalFields = [];

            if (checkout_type == "event") {
                //state.photoEvent = state.selectedProducts[0].picture

                if (products[0].options[0].option_values.length > 1) state.eventSingleMoreChoice = "more"

                const eventOV = products[0].options[0].option_values[0];

                if (state.eventSingleMoreChoice === "one") {
                    state.paylinkDetails.checkout_title = eventOV.label;
                    state.paylinkDetails.checkout_description = eventOV.description;
                    state.paylinkDetails.checkout_price = eventOV.price;
                    state.photoEvent = products[0].picture

                    if (products[0].options[1]) {
                        const eventFields = products[0].options[1].option_values;
                        eventFields.map(field => {
                            state.extraEventFields.push({ label: field.label, description: field.description, price: field.price, qty: field.stock });
                        });
                    }
                } else {
                    //conso
                    products.forEach(product => {

                        state.selectedProducts.push(product);
                        state.checkoutOrder.products.push({
                            product_id: product.id,
                            quantity: 0,
                            options: []
                        });
                        state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);

                        state.availableProducts = state.availableProducts.filter(prod => prod.id !== product.id);
                    });
                }


            } else if (checkout_type == "donation") {
                state.photoDonation = picture
            } else {
                if (products && Array.isArray(products)) {
                    const deliveryProducts = products.filter(product => product.type === "delivery");

                    if (deliveryProducts.length > 0) {
                        checkoutBuilderSlice.caseReducers.setSelectedDeliveryOption(state, { payload: deliveryProducts[0] });
                    }
                    products.filter(product => product.type === "product").forEach(product => {
                        let productPrice = 0;
                        const options_array = [];
                        const base_price = product.init_price;

                        if (product.options.length > 0) {
                            //
                            product.options.forEach(option => {
                                let ov_default = option.option_values[0];
                                productPrice += parseFloat(ov_default.price);
                                options_array.push({ option_id: parseInt(option.id), option_value_id: parseInt(ov_default.id), price: parseFloat(ov_default.price) })
                            })
                        }
                        productPrice += base_price;

                        state.selectedProducts.push(product);
                        state.checkoutOrder.products.push({
                            product_id: product.id,
                            quantity: 0,
                            options: options_array,
                            price: productPrice
                        });
                        state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);
                        state.availableProducts = state.availableProducts.filter(prod => prod.id !== product.id);

                    });
                }
            }


            if (additional_fields && Array.isArray(additional_fields)) {
                additional_fields.forEach(add_field => {
                    state.optionalFields.push(add_field);
                })
            }

            state.paylinkDetails.checkout_expiry_date = convertToDateString(expiry_date);
            checkoutBuilderSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        setPhotoEdited: (state, action) => {
            state.photoEdited = action.payload;
        },

        resetAllTheStates: () => initState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductList.fulfilled, (state, action) => {
                //const checkoutType = checkoutBuilderSlice.checkoutType;
                //in event only event with multiple prices
                state.availableProducts = action.payload;
            })
            .addCase(fetchDeliveryOptions.fulfilled, (state, action) => {
                state.availableDeliveryOptions = action.payload;
            });
    },
});

export const {
    resetAllTheStates,
    updateChekoutType,
    updateActiveTab,
    addOptionalField,
    removeOptionalField,
    updateOptionalField,
    addProduct,
    removeProduct,
    setAfterPayment,
    updatePaylinkDetails,
    addAvailableProduct,
    removeAvailableProduct,
    setSelectedDeliveryOption,
    resetSelectedProducts,
    setCheckoutFormErrors,
    setEventSingleMoreChoice,
    addExtraEventField,
    updateExtraEventField,
    toggleShowExtraEventQuantity,
    setExtraFieldError,
    clearExtraFieldError,
    updateResultedAmount,
    updateProductQuantity,
    updateOptionQuantity,
    addProductToCheckoutOrder,
    removeProductFromCheckoutOrder,
    updateOptionValue,
    savePhotoDonation,
    savePhotoEvent,
    chooseDeliveryOption,
    switchToEditMode,
    updatePaylinkId,
    fillPaylinkEditMode,
    setPhotoEdited,
    removeExtraEventField,
    calculateTotalAmountEvent,
    updateEventQuantity,
    selectEventProduct


} = checkoutBuilderSlice.actions;

export default checkoutBuilderSlice.reducer;
