// import React, { createContext, useState } from 'react';

// const RegisterContext = createContext();

// const RegisterContextProvider = ({ children }) => {
//     const [userDetails, setUserDetails] = useState({
//       first_name: "",
//       last_name: "",
//       email: "",
//       phone_number: "",
//       password: "",
//       password_again: "",
//       legal_status: "",
//       company_address: "",
//       fiscal_number: "",
//       website: "",
//       activity: "",
//       cin_recto: new File([], ""),
//       cin_verso: new File([], ""),
//       business_tax: new File([], ""),
//       business_number: new File([], ""),
//       hash: "",
//       temporary_token: "",
//     });
//     const [progress, setProgress] = useState(0);
//     const [actualStep, setActualStep] = useState(1);

//     return (
//       <RegisterContext.Provider value={{userDetails, setUserDetails, progress, setProgress, actualStep, setActualStep}}>
//         {children}
//       </RegisterContext.Provider>
//     );
// };

// export { RegisterContext, RegisterContextProvider };

import React, { createContext, useState } from "react";

const RegisterContext = createContext();

const RegisterContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    password_again: "",
    legal_status: "",
    company_address: "",
    fiscal_number: "",
    website: "",
    activity: "",
    cin_recto: new File([], ""),
    cin_verso: new File([], ""),
    business_tax: new File([], ""),
    business_number: new File([], ""),
    hash: "",
    temporary_token: "",
  });
  const [progress, setProgress] = useState(0);
  const [actualStep, setActualStep] = useState(1);

  const value = {
    userDetails,
    setUserDetails,
    progress,
    setProgress,
    actualStep,
    setActualStep,
  };
  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  );
};

export { RegisterContext, RegisterContextProvider };
