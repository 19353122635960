import React, { useState } from 'react';
import { Input, InputGroup, InputGroupText, Popover, PopoverBody } from 'reactstrap';
import { FaInfoCircle } from 'react-icons/fa';

const LinkInputWithPopover = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen);
    };

    return (
        <div>
            <InputGroup style={{ borderRadius: "6px" }}>
                <Input
                    type="url"
                    name="website_url"
                    //value={link}

                    style={{ fontSize: "14px", fontWeight: "400" }}
                    className="link-input"
                />
                <InputGroupText
                    style={{ backgroundColor: "white", cursor: "pointer" }}
                //onClick={copyLinkToClipboard}
                >
                    <div id="popover-target">
                        <FaInfoCircle id="info-icon" />
                    </div>
                    <Popover
                        placement="right"
                        isOpen={popoverOpen}
                        target="popover-target"
                        toggle={togglePopover}
                    >
                        <PopoverBody>
                            This is a description about the link.
                        </PopoverBody>
                    </Popover>
                </InputGroupText>
            </InputGroup>
        </div>
    );
};

export default LinkInputWithPopover;
