import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Progress, Container, Row, Col } from "reactstrap";
import {
  FaCheckCircle,
  FaEdit,
  FaPlusCircle,
  FaRegEdit,
  FaRegTimesCircle,
} from "react-icons/fa";
import { ImAttachment } from "react-icons/im";
import { RiCloseCircleLine } from "react-icons/ri";

import "./DropZoneHolder.css";
import { RegisterContext } from "../../../context/auth/RegisterContext";
import { string } from "prop-types";

const MAX_FILE_SIZE = 409600; // 400 Ko
const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "application/pdf"];
const SUPPORTED_FORMATS_IMAGE = ["image/jpeg", "image/png"];
interface DropZoneHolderProps {
  name: string,
  size?: string,
  handleUploadDocument?: (file: any) => void
  typeImage64?: boolean,
  width?: string
  lang?: string
  image?: boolean
}
const DropzoneComponent: React.FC<DropZoneHolderProps> = ({ name, handleUploadDocument = (file: any) => { console.log("") }, size = "45%", typeImage64, width, lang = "fr", image }) => {
  const { userDetails, setUserDetails } = useContext(RegisterContext);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");
  const [hovered, setHovered] = useState(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    setError("");
    if (acceptedFiles.length > 1) {
      setError("Please drop only one file.");
    } else if (!SUPPORTED_FORMATS.includes(acceptedFiles[0].type) && !image) {
      if (lang === "fr") {
        setError("Format de fichier non pris en charge. Seuls les fichiers JPEG ,PNG et PDF sont autorisés.");

      } else {
        setError("File format not supported. Only JPEG, PNG and PDF are allowed.");

      }
    } else if (!SUPPORTED_FORMATS_IMAGE.includes(acceptedFiles[0].type) && image) {
      if (lang === "fr") {
        setError("Format de fichier non pris en charge. Seuls les fichiers JPEG et PNG  sont autorisés.");

      } else if (acceptedFiles[0].size > MAX_FILE_SIZE) {
        if (lang === "fr") {
          setError("La taille du fichier est trop grande. La taille maximale est de 400 Ko.");
        } else {
          setError("File size is too big. Max size is 400 ko.");
        }
      } else {
        setError("File format not supported. Only JPEG and PNG are allowed.");

      }

    }
    else {
      setUserDetails((prevData: any) => ({ ...prevData, [name]: acceptedFiles[0] }));
      let formatName = acceptedFiles[0].name.substring(0, 15)
      formatName += "..."
      setFileName(formatName);
      if (typeImage64) {
        const reader = new FileReader();
        if (acceptedFiles[0] != null) {
          reader.readAsDataURL(acceptedFiles[0]);
          reader.onload = function () {
            handleUploadDocument(reader.result ? reader.result : "")
          }
        }
      } else {
        handleUploadDocument(acceptedFiles[0]);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      style={{ width: width }}
      className={`${error ? "dropzone-container-error" : "dropzone-container"
        } ${hovered ? "hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        {!fileName && !error && (
          <>
            <div className="text-center">
              <FaPlusCircle
                color="#92C3FF"
                size="50px"
              // style={{ marginLeft: size }}
              />
            </div>
          </>

        )}
        {fileName && (
          <>
            <div className="text-center">
              <FaCheckCircle
                color="#92C3FF"
                size="50px"
              // style={{ marginLeft: size }}
              />
            </div>

          </>
        )}
        {error && (
          <><div className="text-center">
            <FaRegTimesCircle
              color="red"
              size="50px"
            // style={{ marginLeft: size }}
            />
          </div></>

        )}

        {error && (
          <>
            <div className="text-center">
              <p
                className="text-danger"
                style={{ textAlign: "center", marginTop: "10px", fontSize: "15px" }}
              >
                {error}
              </p>
            </div>
          </>
        )}
        {fileName && (
          <>
            <div className="text-center">
              <p
                className="text-success"
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  textDecoration: "underline",
                }}
              >
                {fileName}  <FaEdit color="success" />
              </p>
            </div>
          </>
        )}
        <div style={{ margin: "20px" }}>
          {!fileName && !error && (
            <>
              <div className="upload-text">{lang === "fr" ? "Téléchargez votre document" : "upload your document"}</div>
              {
                image ? (<div className="upload-format">{lang === "fr" ? "Format JPEG ou PNG" : "in JPEG or PNG"}</div>)
                  : (<div className="upload-format">{lang === "fr" ? "Format JPEG ou PDF" : "in JPEG or PDF"}</div>)
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropzoneComponent;
