import React, { useContext } from "react";
import { createContext } from "react";
//import { Ability } from "@casl/ability";
import { Can, createContextualCan } from "@casl/react";
import { defineAbility } from "@casl/ability";

export const AbilityContext = createContext();

export const ShowHideCan = createContextualCan(AbilityContext.Consumer);
export const AllowedCan = ({ do: action, on: subject, children }) => (
  <AbilityContext.Consumer>
    {(ability) => {
      if (ability) {
        const allowed = ability.can(action, subject);
        return children(allowed);
      }
      return null;
    }}
  </AbilityContext.Consumer>
);

export const ForbiddenPage = ({ permission, children }) => (
  <Can I={permission}>
    {({ allowed }) => {
      if (allowed) {
        return children;
      } else {
        return (
          <div>
            403 - Forbidden: You don't have permission to access this page.
          </div>
        );
      }
    }}
  </Can>
);

export const AnyAllowedCan = ({ permissions, children }) => (
  <AbilityContext.Consumer>
    {(ability) => {
      if (ability) {
        const allowed = permissions.some((permission) =>
          ability.can(permission.do, permission.on)
        );
        return children(allowed);
      }
      return null;
    }}
  </AbilityContext.Consumer>
);

export const AtElseCan = ({ permissions, children }) => {
  const allowed = useAnyPermission(permissions);

  return allowed ? children : null;
};

const useAnyPermission = (permissions) => {
  const ability = useContext(AbilityContext);

  if (!ability) {
    return false;
  }

  return permissions.some((permission) =>
    ability.can(permission.do, permission.on)
  );
};

export const getAbility = (permissions) => {
  return defineAbility((can) => {
    Object.keys(permissions).forEach((resource) => {
      permissions[resource].forEach((permission) => {
        can(permission, resource);
      });
    });
  });
};
