
import React from "react";
import { DotLoader } from "react-spinners";
import { Modal, Row } from "reactstrap";
interface Props {
    isOpen: boolean;
    toogle?: () => void;
}
const LoadingModal: React.FC<Props> = (props: Props) => {
    const { isOpen, toogle } = props
    return (<>
        <Modal isOpen={isOpen} toggle={toogle} centered size="md" style={{ maxWidth: "19%" }}>
            <Row className="d-flex justify-content-center" style={{ marginTop: "20%", marginRight: "1%", marginBottom: "15%" }}>
                <DotLoader
                    color="#709DD3"
                    loading
                    size={66}
                    speedMultiplier={1}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </Row>

        </Modal>
    </>)

}


export { LoadingModal }