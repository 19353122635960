import { configureStore, combineReducers } from '@reduxjs/toolkit';
import profileReducer from './slices/profileSlice';
import userReducer from './slices/userSlice';
import checkoutBuilderReducer from './slices/checkoutBuilderSlice';
import manageSettingReducer from './slices/manageSettingSlice';
import permissionsReducer from './slices/permissionsSlice';
import companyReducer from './slices/companySlice';
import paymentCheckoutReducer from './slices/paymentCheckoutSlice';
import { resetStore } from '../redux/slices/reset';
import { loadState, saveState } from './localStorage';
import resetReducer from '../redux/slices/resetStore'
// Combine reducers
const appReducer = combineReducers({
    profile: profileReducer,
    user: userReducer,
    checkoutBuilder: checkoutBuilderReducer,
    manageSetting: manageSettingReducer,
    permissions: permissionsReducer,
    company: companyReducer,
    paymentCheckout: paymentCheckoutReducer,
    reset: resetReducer,

});

const rootReducer = (state, action) => {
    if (action.type === 'reset') {
        state = undefined;
    }
    return appReducer(state, action);
};

// Custom middleware for reset
const resetMiddleware = (store) => (next) => (action) => {
    if (action.type === resetStore.type) {
        store.dispatch({ type: 'RESET_ACTION' }); // Dispatch a reset action to all reducers
    }
    return next(action);
};
const preloadedState = loadState();

const store = configureStore({
    reducer: rootReducer,
    //middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), resetMiddleware],
    //preloadedState:preloadedState,
});
/* store.subscribe(() => {
    saveState(store.getState());
}); */
export default store;