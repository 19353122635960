// Preview.tsx
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Popover, PopoverBody, Progress, Row, Spinner, TabContent, TabPane, Table } from 'reactstrap';
import { ShowHideCan } from '../../utils/permissions';
import "./CheckoutPreview.css";
import { FaArrowLeft, FaArrowRight, FaCaretDown, FaCheck, FaDesktop, FaDonate, FaHandHoldingHeart, FaInfoCircle, FaList, FaLock, FaMobile, FaPlus, FaRegFolderOpen, FaShoppingCart, FaSyncAlt, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { MdOutlineInstallDesktop, MdProductionQuantityLimits } from 'react-icons/md';
import { CiDeliveryTruck, CiShare2, CiStar } from 'react-icons/ci';
import companyLogo from "../../assets/images/image_4.png";
import { ThunkDispatch } from '@reduxjs/toolkit';
import { updateActiveTab, addOptionalField, addProduct, fetchProductList, fetchDeliveryOptions, removeProduct, updateChekoutType, setAfterPayment, updatePaylinkDetails, removeAvailableProduct, setSelectedDeliveryOption, addAvailableProduct, resetSelectedProducts, setCheckoutFormErrors, setEventSingleMoreChoice, addExtraEventField, updateExtraEventField, toggleShowExtraEventQuantity, setExtraFieldError, clearExtraFieldError, updateResultedAmount, updateProductQuantity, updateOptionQuantity, addProductToCheckoutOrder, updateOptionValue, removeProductFromCheckoutOrder, chooseDeliveryOption, updateEventQuantity } from '../../redux/slices/checkoutBuilderSlice';
import IconToExplain from '../../components/ui/IconToExplain';
import { TiWarningOutline } from 'react-icons/ti';
import logo from "../../assets/images/logo_paymee.png";
import { t } from 'i18next';
import edinarPaymeeVisaMc from "../../assets/images/edinar_paymee_visa_mc.png";
import successImg from '../../assets/images/success.gif';
import emptyProductImg from '../../assets/images/empty_product_img.png';
import PButton from '../../components/form/button/Button';
import { IoTicketOutline } from 'react-icons/io5';
import emptyMerchantImg from '../../assets/images/empty_merchant_img.svg';
import { FaRegClone } from 'react-icons/fa6';
import { BsTrash } from 'react-icons/bs';
import QtyInput from '../../components/form/input/QuantityInput';
import PayerContactForm from '../gateway/PayerContactForm';
import EventItem from './EventItemPreview';

interface PreviewCheckoutProps {
    id?: string
}
const CheckoutPreview: React.FC<PreviewCheckoutProps> = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const optionalFields = useSelector((state: any) => state.checkoutBuilder.optionalFields);
    const selectedProducts = useSelector((state: any) => state.checkoutBuilder.selectedProducts);
    const selectedDeliveryOption = useSelector((state: any) => state.checkoutBuilder.selectedDeliveryOption)
    const extraEventFields = useSelector((state: any) => state.checkoutBuilder.extraEventFields)

    const user = useSelector((state: any) => state.user);
    const profile = useSelector((state: any) => state.profile);

    const {
        checkoutType: selectedPaylinkType,
        activeTab,
        paylinkDetails,
        payerInfos,
        checkoutOrder,
        photoDonation,
        photoEvent,
        eventSingleMoreChoice
    } = useSelector((state: any) => state.checkoutBuilder);

    const [renderedProductIds, setRenderedProductIds] = useState([]);

    const [popoverOpen, setPopoverOpen] = useState(false);

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    useEffect(() => {
        dispatch(fetchProductList());
        dispatch(fetchDeliveryOptions());
    }, [dispatch, selectedPaylinkType]);

    const handleProductQuantityChange = (productIndex: any, qty: any) => {
        dispatch(updateProductQuantity({ productIndex, quantity: qty }));
    };

    const handleOptionQuantityChange = (p_id: any, ov_id: any, qty: any) => {
        dispatch(updateEventQuantity({ p_id: p_id, ov_id: ov_id, qty: qty }));
    };

    const handleAddAnotherProductToOrder = (p_id: any, p_index: any) => {
        dispatch(addProductToCheckoutOrder({ p_id, p_index }))
    }

    const handleDeleteProductFromOrder = (p_id: any, index: any) => {
        dispatch(removeProductFromCheckoutOrder({ p_id: p_id, index }));
    }

    const handleCheckoutProductOptionSelect = (p_index: any, p_id: any, op_id: any, ov_id: any) => {
        dispatch(updateOptionValue({ p_index: p_index, p_id: p_id, o_id: op_id, ov_id: ov_id, qty: 0 }));
    }

    const handleDeliveryChoosed = (event: any) => {
        event.stopPropagation();
        if (event.target.type === "checkbox") {
            if (event.target.checked) { dispatch(chooseDeliveryOption(parseInt(event.target.value))); } else { dispatch(chooseDeliveryOption(null)); }
        } else {
            dispatch(chooseDeliveryOption(parseInt(event.target.value)));
        }
    };

    const handleLoadPayment = () => {
        console.log()
    }

    const handleExtrasQuantityChange = (p_id: any, ov_id: any, qty: any) => {
        //dispatch(updateExtraQuantity({ p_id: p_id, ov_id: ov_id, qty: qty }));
    };
    return (
        <>
            <Row className="justify-content-between">
                <Col md="4">
                    <span id="info-icon" onClick={togglePopover}>
                        Preview <FaInfoCircle />
                    </span>
                </Col>
                <Col md="2" className='text-center'>
                    <div style={{ backgroundColor: "#fff" }}>
                        <FaMobile
                        //onClick={onClickMobile} 
                        />
                        <FaDesktop

                        //onClick={onClickDesktop} 
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <div className="mx-4 my-4" style={{ backgroundColor: "#fff", minHeight: "1000px", border: "1px solid gray" }} >
                        <Row className="m-0" style={{ height: "10%", backgroundColor: "#f6f8fa" }}>
                            <Col md={12} className="p-0">
                                <InputGroup style={{ borderRadius: "50px" }}>
                                    <InputGroupText style={{ backgroundColor: "white" }}>
                                        <FaLock style={{ fontSize: "16px" }} />
                                    </InputGroupText>
                                    <Input
                                        placeholder={t("search").toString()}
                                        style={{ height: "35px", width: "200px", cursor: "default" }}
                                        value="https://app.paymee.tn/gateway/paylinks/f0a4d08f7c3/checkout"
                                        readOnly

                                    />
                                    <InputGroupText style={{ backgroundColor: "white" }}>
                                        <MdOutlineInstallDesktop style={{ fontSize: "16px" }} />
                                    </InputGroupText>
                                    <InputGroupText style={{ backgroundColor: "white" }}>
                                        <CiShare2 style={{ fontSize: "16px" }} />
                                    </InputGroupText>
                                    <InputGroupText style={{ backgroundColor: "white" }}>
                                        <CiStar style={{ fontSize: "16px" }} />
                                    </InputGroupText>
                                </InputGroup>
                            </Col>

                        </Row>
                        <div
                            style={{
                                backgroundColor: "#fefeff",
                                paddingLeft: "5%",
                                paddingRight: "5%",
                                paddingBottom: "10%",
                                paddingTop: "10%",
                                width: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            <Card className='border-0' style={{ backgroundColor: "#fff", padding: "0" }}>
                                <CardBody className='' style={{ padding: "0", minHeight: "1000px" }}>
                                    <Progress className="progress" value={50} />
                                    {
                                        activeTab === "1" ?
                                            (
                                                <>
                                                    {
                                                        selectedPaylinkType === "" ?
                                                            (
                                                                <>
                                                                    <Container style={{ height: "500px" }}>
                                                                        <Row className="d-flex align-items-center justify-content-center h-100">
                                                                            <Col sm={12} className="d-flex justify-content-center">
                                                                                <Spinner color="primary" />
                                                                            </Col>
                                                                            <Col sm={12} className="text-center">
                                                                                <span
                                                                                    className="mt-1"
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontStyle: "normal",
                                                                                        fontWeight: "600",
                                                                                        fontSize: "20px",
                                                                                        lineHeight: "24px",
                                                                                        color: "#034DA3",
                                                                                    }}
                                                                                >
                                                                                    Choose a paylink type to <br /> see in real-time the preview section
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                </>
                                                            ) : selectedPaylinkType === "sell-products" ?
                                                                (
                                                                    <>
                                                                        <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                                                            <span className="payment-step-text payment-step-text--gray">Etape 1/2</span>
                                                                        </div>
                                                                        <Row className="m-4">
                                                                            <Col xs="auto">
                                                                                <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "15px", padding: "4px" }}>
                                                                                    <img
                                                                                        className="merchant-logo-img"
                                                                                        src={user && user.logo ? user.logo : emptyMerchantImg}
                                                                                        width="30px"
                                                                                        height="30px"
                                                                                        style={{ borderRadius: "10px" }}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="d-flex align-items-end py-2">
                                                                                <span className="merchant-name" style={{ fontSize: "1.5rem", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                                                                    {user && user.company}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row style={{ marginBottom: "20%" }}>
                                                                            <Col>
                                                                                <Row className='text-start mx-4' >
                                                                                    <p className='' style={{
                                                                                        color: "#BCBCBC",
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "400",
                                                                                        fontFamily: "Inter",
                                                                                        lineHeight: "17px",
                                                                                        wordWrap: "break-word",
                                                                                        overflowWrap: "break-word",
                                                                                        wordBreak: "break-word",
                                                                                    }}>
                                                                                        {paylinkDetails.checkout_description}
                                                                                    </p>
                                                                                </Row>
                                                                                <hr
                                                                                    className="my-1"
                                                                                    style={{ marginLeft: "8%", width: "84%" }}
                                                                                />
                                                                                <Row className="j-c-center my-3 mx-3">
                                                                                    <Col
                                                                                        xs={12}
                                                                                        className='d-flex text-start '
                                                                                    >
                                                                                        <span
                                                                                            className="resulted-amount"
                                                                                            style={{
                                                                                                textAlign: "left",
                                                                                                color: "#DAAD66",
                                                                                                fontFamily: "Inter",
                                                                                                fontSize: "1.5rem",
                                                                                                fontStyle: "normal",
                                                                                                fontWeight: "900",
                                                                                                lineHeight: "normal",
                                                                                            }}>
                                                                                            {checkoutOrder.resultedAmount === 0 ? 0 : checkoutOrder.resultedAmount.toFixed(3)} <small>TND</small>
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='mx-4 my-2 product-list px-2' style={{ maxHeight: "40vh", overflowY: "auto" }}>
                                                                                    <Col xs={12}>
                                                                                        {
                                                                                            checkoutOrder.products && checkoutOrder.products.length > 0 && checkoutOrder.products.map((check_product: any, index: any) => {
                                                                                                const product = selectedProducts.find((prod: any) => prod.id == check_product.product_id);
                                                                                                const firstOcc = checkoutOrder.products.findIndex((prod: any) => prod.product_id === check_product.product_id);
                                                                                                let hasMultipleOptionValues = false;
                                                                                                if (product && product.options.length > 0) hasMultipleOptionValues = product.options.some((option: any) => option.option_values.length > 1);

                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            product && <>
                                                                                                                <hr className=""
                                                                                                                    style={{
                                                                                                                        marginBottom: "10px",
                                                                                                                        marginTop: "10px"
                                                                                                                    }} />
                                                                                                                <Row
                                                                                                                    className={`product-item ${check_product.quantity === 0 && 'product-item-disabled'}`}
                                                                                                                    style={{ borderRadius: "20px", paddingTop: "1%", paddingBottom: "1%" }}>
                                                                                                                    <Col xs={12}>
                                                                                                                        <Row className='d-flex align-items-start w-100'>

                                                                                                                            <Col sm={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                                <div
                                                                                                                                    className='product-item-img-container'
                                                                                                                                    style={{
                                                                                                                                        border: "1px solid #ddd",
                                                                                                                                        borderRadius: "10px",
                                                                                                                                        padding: "",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <img
                                                                                                                                        className="product-item-img"
                                                                                                                                        src={product.picture ? product.picture : emptyProductImg}
                                                                                                                                        alt="The product image"
                                                                                                                                        width="30px"
                                                                                                                                        height="30px"
                                                                                                                                        style={{ borderRadius: '10px' }}
                                                                                                                                    />
                                                                                                                                </div>

                                                                                                                            </Col>

                                                                                                                            <Col sm={7} className='d-flex flex-column justify-content-start text-start'>
                                                                                                                                <span
                                                                                                                                    className="product-item-label d-block"
                                                                                                                                    style={{ fontSize: "0.75rem" }}
                                                                                                                                >{product.label}</span>
                                                                                                                                <span
                                                                                                                                    className="product-item-description"
                                                                                                                                    style={{ fontSize: "0.5rem" }}
                                                                                                                                >{product.description}</span>
                                                                                                                            </Col>

                                                                                                                            <Col sm={3} className="d-flex justify-content-end">
                                                                                                                                <span
                                                                                                                                    className="product-item-price"
                                                                                                                                    style={{ fontSize: "0.75rem" }}
                                                                                                                                >
                                                                                                                                    {check_product.price} <small>TND</small>
                                                                                                                                </span>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row className='d-flex align-items-center w-100 mt-1'>
                                                                                                                            <Col sm={2} className="d-flex flex-column align-items-start">
                                                                                                                                {product.options.length > 0 && hasMultipleOptionValues && firstOcc === index &&

                                                                                                                                    <FaRegClone
                                                                                                                                        className=""
                                                                                                                                        style={{ color: '#63a4ff', fontSize: "1rem", cursor: check_product.quantity === 0 ? "not-allowed" : "pointer" }}
                                                                                                                                        onClick={() => handleAddAnotherProductToOrder(product.id, index)}
                                                                                                                                    />
                                                                                                                                }

                                                                                                                                {firstOcc !== index &&
                                                                                                                                    <BsTrash
                                                                                                                                        className=""
                                                                                                                                        style={{ color: '#D83A52', fontSize: "1rem", cursor: "pointer" }}
                                                                                                                                        onClick={() => handleDeleteProductFromOrder(product.id, index)}
                                                                                                                                    />
                                                                                                                                }
                                                                                                                            </Col>
                                                                                                                            <Col sm={7} className="">
                                                                                                                                <Row className="d-flex justify-content-start align-items-end h-100">
                                                                                                                                    {
                                                                                                                                        product.options.map((option: any, op_index: any) => <>
                                                                                                                                            <Col sm={6} className="product-option d-flex justify-content-center">
                                                                                                                                                <Input
                                                                                                                                                    type="select"
                                                                                                                                                    name="optional_field_type"
                                                                                                                                                    id="optional_field_type"
                                                                                                                                                    style={{
                                                                                                                                                        width: "100px",
                                                                                                                                                        height: "2.5vh",
                                                                                                                                                        fontSize: "0.5rem",
                                                                                                                                                        fontWeight: "300",
                                                                                                                                                        fontFamily: 'Inter',
                                                                                                                                                        lineHeight: "8px",
                                                                                                                                                        cursor: "pointer",
                                                                                                                                                        border: "0.5px solid #CECECE"
                                                                                                                                                    }}
                                                                                                                                                    onChange={(e) => handleCheckoutProductOptionSelect(index, product.id, option.id, e.target.value)}
                                                                                                                                                    value={checkoutOrder.products[index].options[op_index].option_value_id}
                                                                                                                                                >
                                                                                                                                                    {option.option_values.map((ov: any, ov_index: any) => (
                                                                                                                                                        <option value={ov.id}>{ov.label}</option>
                                                                                                                                                    ))}
                                                                                                                                                </Input>
                                                                                                                                            </Col>
                                                                                                                                        </>
                                                                                                                                        )}

                                                                                                                                </Row>
                                                                                                                            </Col>
                                                                                                                            <Col sm={3} className="d-flex justify-content-end">
                                                                                                                                <QtyInput
                                                                                                                                    quantity={checkoutOrder.products[index].quantity || 0}
                                                                                                                                    //maxLimit={product.qty === 0 ? undefined : product.qty}
                                                                                                                                    //minLimit="0"
                                                                                                                                    onQuantityChange={(qty) => handleProductQuantityChange(index, qty)}
                                                                                                                                    smallSize

                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </Col>
                                                                                                                </Row >
                                                                                                                {index == checkoutOrder.products.length - 1 && (
                                                                                                                    <hr
                                                                                                                        style={{
                                                                                                                            marginBottom: "10px",
                                                                                                                            marginTop: "10px",
                                                                                                                        }}
                                                                                                                        className=""
                                                                                                                    />
                                                                                                                )
                                                                                                                }
                                                                                                            </>
                                                                                                        }

                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                                {
                                                                                    selectedDeliveryOption &&
                                                                                    <>
                                                                                        <Row className="j-c-center mx-3 my-3">
                                                                                            <span style={{
                                                                                                fontFamily: "Inter",
                                                                                                fontSize: "1rem",
                                                                                                fontWeight: "600",
                                                                                                lineHeight: "30px",
                                                                                                textAlign: "left",
                                                                                                color: "#515151"
                                                                                            }}>Livraison</span>
                                                                                        </Row>
                                                                                        <div className="delivery-options mx-5">
                                                                                            {selectedDeliveryOption.options[0].option_values.map((ov: any, index: any) =>
                                                                                                <Row
                                                                                                    className={`delivery-option ${checkoutOrder.delivery == ov.id && 'delivery-option--selected'} my-2 p-1 d-flex justify-content-between`}
                                                                                                    onClick={() => checkoutOrder.delivery == ov.id
                                                                                                        ? dispatch(chooseDeliveryOption(null))
                                                                                                        : dispatch(chooseDeliveryOption(parseInt(ov.id)))}
                                                                                                >
                                                                                                    <Col sm={5} className='d-flex flex-column justify-content-start text-start h-100'>
                                                                                                        <div className="text">
                                                                                                            <div className="label" style={{ color: "#515151", fontSize: "0.75rem", fontWeight: "600", fontFamily: "Inter", lineHeight: "24px" }}>
                                                                                                                {ov.label}
                                                                                                            </div>
                                                                                                            <div className="description" style={{ color: "#BCBCBC", fontSize: "0.5rem", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                                                                                                {ov.description}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col sm={3}>
                                                                                                        <div className="text" style={{ textAlign: "right" }}>
                                                                                                            <div className="label" style={{ color: "#515151", fontSize: "0.5rem", fontWeight: "600", fontFamily: "Inter", lineHeight: "18px" }}>
                                                                                                                {ov.price} TND
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="description mt-3"
                                                                                                                style={{ color: "#CECCCC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px", textAlign: "right" }}>
                                                                                                                <FormGroup
                                                                                                                    check
                                                                                                                    inline
                                                                                                                    style={{ marginRight: "0px" }}
                                                                                                                >
                                                                                                                    <Input
                                                                                                                        style={{ cursor: "pointer", width: "20px", height: "20px" }}
                                                                                                                        type="checkbox"
                                                                                                                        onChange={handleDeliveryChoosed}
                                                                                                                        value={ov.id}
                                                                                                                        checked={checkoutOrder.delivery == ov.id}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Col>

                                                                                                </Row>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                                {
                                                                                    checkoutOrder.products && checkoutOrder.products.length > 0 &&
                                                                                    <>
                                                                                        <hr
                                                                                            className="my-3"
                                                                                            style={{ marginLeft: "8%", width: "84%" }}
                                                                                        />
                                                                                        <div className="mx-5 d-flex justify-content-between">
                                                                                            <div>
                                                                                                <span style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "1rem", color: "#515151" }}>Total</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span
                                                                                                    className="label mx-2"
                                                                                                    style={{ color: "#515151", fontSize: "0.9rem", fontWeight: "600", fontFamily: "Inter", lineHeight: "18px" }}
                                                                                                >
                                                                                                    {checkoutOrder.resultedAmount} TND
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>

                                                                                }

                                                                            </Col>
                                                                            <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                                                                <Row className='my-3 mx-5' style={{ overflowY: "auto", maxHeight: "800px", overflowX: "hidden" }}>
                                                                                    <FormGroup className="mx-2">
                                                                                        <Label for='checkout-firstname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                            <span className='' style={{ marginRight: "5px" }}>First name</span>
                                                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                        </Label>
                                                                                        <Input
                                                                                            name="checkout-firstname"
                                                                                            type="text"
                                                                                            className="checkout-firstname"
                                                                                            placeholder='Enter your firstname'
                                                                                            value={payerInfos.firstname}
                                                                                            style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                            valid={false}
                                                                                            invalid={false}
                                                                                        />

                                                                                        <FormFeedback>
                                                                                            <TiWarningOutline color='#DF1B41' />
                                                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                        </FormFeedback>
                                                                                    </FormGroup>
                                                                                    <FormGroup className="mx-2">
                                                                                        <Label for='checkout-lastname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                            <span className='' style={{ marginRight: "5px" }}>Last name</span>
                                                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                        </Label>
                                                                                        <Input
                                                                                            name="checkout-lastname"
                                                                                            type="text"
                                                                                            className="checkout-lastname"
                                                                                            placeholder='Enter your lastname'
                                                                                            value={payerInfos.lastname}
                                                                                            style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                            valid={false}
                                                                                            invalid={false}
                                                                                        />

                                                                                        <FormFeedback>
                                                                                            <TiWarningOutline color='#DF1B41' />
                                                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                        </FormFeedback>
                                                                                    </FormGroup>
                                                                                    <FormGroup className="mx-2">
                                                                                        <Label for='checkout-email' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                            <span className='' style={{ marginRight: "5px" }}>Email</span>
                                                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                        </Label>
                                                                                        <Input
                                                                                            name="checkout-email"
                                                                                            type="text"
                                                                                            className="checkout-email"
                                                                                            placeholder='Enter your email'
                                                                                            value={payerInfos.email}
                                                                                            style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                            valid={false}
                                                                                            invalid={false}
                                                                                        />

                                                                                        <FormFeedback>
                                                                                            <TiWarningOutline color='#DF1B41' />
                                                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                        </FormFeedback>
                                                                                    </FormGroup>
                                                                                    <FormGroup className="mx-2">
                                                                                        <Label for='checkout-phone' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                            <span className='' style={{ marginRight: "5px" }}>Phone</span>
                                                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                        </Label>
                                                                                        <Input
                                                                                            name="checkout-phone"
                                                                                            type="text"
                                                                                            className="checkout-phone"
                                                                                            placeholder='Enter your phone'
                                                                                            value={payerInfos.phone}
                                                                                            style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                            valid={false}
                                                                                            invalid={false}

                                                                                        />

                                                                                        <FormFeedback>
                                                                                            <TiWarningOutline color='#DF1B41' />
                                                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                        </FormFeedback>
                                                                                    </FormGroup>
                                                                                    {selectedDeliveryOption &&
                                                                                        <FormGroup className="mx-2" >
                                                                                            <Label for="payer-address" className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px", textTransform: "capitalize" }}>Address</span>
                                                                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                                <IconToExplain popId={`payer-address-pop`} content="add the delivery address" direction="right" />
                                                                                            </Label>

                                                                                            <Input
                                                                                                name="payer-address"
                                                                                                type="textarea"
                                                                                                className="payer-address"
                                                                                                placeholder="Enter your address"
                                                                                                rows={2}
                                                                                                maxLength={100}
                                                                                                style={{ height: "3vh", fontSize: "0.75rem" }}
                                                                                            />

                                                                                            < FormFeedback FormFeedback >
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}></span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>
                                                                                    }

                                                                                    {optionalFields.map((field: any, index: any) => (
                                                                                        <FormGroup className="mx-2">
                                                                                            <Label for={`checkout_${field.label.replace(/\s+/g, "_")}`} className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px", textTransform: "capitalize" }}>{field.label}</span>
                                                                                                {field.required && <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>}
                                                                                                <IconToExplain popId={`checkout-${field.label.replace(/\s+/g, "_")}-pop`} content={field.description} direction="right" />
                                                                                            </Label>
                                                                                            {
                                                                                                (field.type || field.field_type) === "text" ?
                                                                                                    <Input
                                                                                                        name={`checkout${field.label}`}
                                                                                                        type={field.characterLimits > 20 ? "textarea" : "text"}
                                                                                                        className={`checkout${field.label}`}
                                                                                                        id={`checkout${field.label}`}
                                                                                                        placeholder='Enter ...'
                                                                                                        //onChange={handlePaylinkDetailsInputChange}
                                                                                                        rows={4}
                                                                                                        maxLength={field.characterLimits}
                                                                                                        style={{ height: "3vh", fontSize: "0.75rem" }}
                                                                                                    />
                                                                                                    : (field.type === "checkbox" || field.field_type === "checkbox") ?
                                                                                                        <Row>
                                                                                                            {field.options &&
                                                                                                                field.options.map((cb_option: any, index: any) =>
                                                                                                                    <Col xs="auto">
                                                                                                                        <FormGroup
                                                                                                                            check
                                                                                                                            inline
                                                                                                                        >
                                                                                                                            <Input
                                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                                type="checkbox"
                                                                                                                            />
                                                                                                                            <Label check style={{ fontWeight: "bold", }}>
                                                                                                                                {cb_option}
                                                                                                                            </Label>
                                                                                                                        </FormGroup>
                                                                                                                    </Col>
                                                                                                                )
                                                                                                            }
                                                                                                        </Row>
                                                                                                        : (field.type === "dropdownlist" || field.field_type === "dropdownlist") ?
                                                                                                            <Row>
                                                                                                                {field.displayAsRadiobutton && field.options &&
                                                                                                                    field.options.map((cb_option: any, index: any) =>
                                                                                                                        <Col xs="auto">
                                                                                                                            <FormGroup check inline>
                                                                                                                                <Input
                                                                                                                                    style={{ cursor: "pointer" }}
                                                                                                                                    type="radio"
                                                                                                                                    name={field.label}
                                                                                                                                />
                                                                                                                                <Label check style={{ fontWeight: "bold" }}>
                                                                                                                                    {cb_option}
                                                                                                                                </Label>
                                                                                                                            </FormGroup>
                                                                                                                        </Col>
                                                                                                                    )
                                                                                                                }
                                                                                                                {!field.displayAsRadiobutton && field.options &&
                                                                                                                    <Input
                                                                                                                        type="select"
                                                                                                                        className='mt-2'
                                                                                                                    >
                                                                                                                        {
                                                                                                                            field.options.map((cb_option: any, index: any) => <option value={cb_option}>{cb_option}</option>)
                                                                                                                        }
                                                                                                                    </Input>
                                                                                                                }
                                                                                                            </Row>
                                                                                                            : <></>}
                                                                                            <FormFeedback FormFeedback >
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>
                                                                                    ))}
                                                                                    <FormGroup className="mx-2">
                                                                                        <Label for='checkout-commentary' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                            <span className='' style={{ marginRight: "5px" }}>Commentary</span>
                                                                                            <IconToExplain popId="checkout-commentary-pop" content="you can leave here a comment to the merchat" direction="right" />
                                                                                        </Label>
                                                                                        <Input
                                                                                            name="checkout-commentary"
                                                                                            type="textarea"
                                                                                            className="checkout-commentary"
                                                                                            id="checkout-description"
                                                                                            placeholder='Leave comment.'
                                                                                            //onChange={handlePaylinkDetailsInputChange}
                                                                                            rows={3}
                                                                                            value={payerInfos.commentary}
                                                                                            style={{ fontSize: "0.75rem" }}
                                                                                        />

                                                                                        <FormFeedback>
                                                                                            <TiWarningOutline color='#DF1B41' />
                                                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                        </FormFeedback>
                                                                                    </FormGroup>

                                                                                    <Button
                                                                                        className="button-without-icon mx-2 my-5 float-start"
                                                                                        //disabled={carryOnToPayment()}
                                                                                        style={{
                                                                                            borderRadius: "4px",
                                                                                            //width: "100%",
                                                                                        }}
                                                                                    >
                                                                                        Continuer vers le paiement
                                                                                    </Button>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                ) : selectedPaylinkType === "accept-donation" ?
                                                                    (
                                                                        <>
                                                                            <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                                                                <span className="payment-step-text payment-step-text--gray">Etape 1/2</span>
                                                                            </div>
                                                                            <Row className="m-5">
                                                                                <Col xs="auto">
                                                                                    <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                                                                        <img
                                                                                            className="merchant-logo-img"
                                                                                            src={user.logo ? user.logo : emptyMerchantImg}
                                                                                            width="40px"
                                                                                            height="40px"
                                                                                            style={{ borderRadius: "10px" }}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="d-flex align-items-end py-2">
                                                                                    <span className="merchant-name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                                                                        {user && user.company}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>

                                                                                <Col>
                                                                                    <Row className="row mx-5" style={{ width: '80%' }}>
                                                                                        <img
                                                                                            src={photoDonation ? photoDonation : emptyProductImg}
                                                                                            alt="Placeholder"
                                                                                            style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '50px' }}
                                                                                        />
                                                                                    </Row>
                                                                                    <Row className='justify-content-center mx-5 my-3'>
                                                                                        <p className='w-75 text-start' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                                                                            {paylinkDetails.checkout_description}
                                                                                        </p>
                                                                                    </Row>
                                                                                    <Row className='justify-content-center mx-5 my-3'>
                                                                                        <hr
                                                                                            className="my-3 mx-5 w-75"
                                                                                        />
                                                                                    </Row>
                                                                                    <Row
                                                                                        className="d-flex justify-content-center"
                                                                                    >
                                                                                        <Col sm={4}>
                                                                                            <span style={{ fontFamily: "Inter", fontSize: "1rem", fontWeight: "600", lineHeight: "24px" }}>Donation amont</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingLeft: "3px",
                                                                                            marginTop: "24px",
                                                                                            marginBottom: "12px%",
                                                                                        }}
                                                                                    >
                                                                                        <Col sm={5}>
                                                                                            <FormGroup>
                                                                                                <InputGroup>
                                                                                                    <Input
                                                                                                        name="checkout_amount_donation"
                                                                                                        placeholder="0.000"
                                                                                                    />
                                                                                                    <InputGroupText style={{ backgroundColor: "white" }}>
                                                                                                        TND
                                                                                                    </InputGroupText>
                                                                                                </InputGroup>
                                                                                            </FormGroup>
                                                                                        </Col>

                                                                                    </Row>
                                                                                </Col>
                                                                                <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                                                                    <Row className='my-3 mx-5' style={{ overflowY: "auto", maxHeight: "800px", overflowX: "hidden" }}>
                                                                                        <FormGroup className="mx-2">
                                                                                            <Label for='checkout-firstname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px" }}>First name</span>
                                                                                                <IconToExplain popId="checkout-firstname-pop" content="The payer email" direction="right" />
                                                                                            </Label>
                                                                                            <Input
                                                                                                name="checkout-firstname"
                                                                                                type="text"
                                                                                                className="checkout-firstname"
                                                                                                placeholder='Enter your firstname'
                                                                                                value={payerInfos.firstname}
                                                                                                style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                valid={false}
                                                                                                invalid={false}
                                                                                            />

                                                                                            <FormFeedback>
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mx-2">
                                                                                            <Label for='checkout-lastname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px" }}>Last name</span>
                                                                                                <IconToExplain popId="checkout-lastname-pop" content="The payer lastname" direction="right" />
                                                                                            </Label>
                                                                                            <Input
                                                                                                name="checkout-lastname"
                                                                                                type="text"
                                                                                                className="checkout-lastname"
                                                                                                placeholder='Enter your lastname'
                                                                                                value={payerInfos.lastname}
                                                                                                style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                valid={false}
                                                                                                invalid={false}
                                                                                            />

                                                                                            <FormFeedback>
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mx-2">
                                                                                            <Label for='checkout-email' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px" }}>Email</span>
                                                                                                <IconToExplain popId="checkout-email-pop" content="The payer email" direction="right" />
                                                                                            </Label>
                                                                                            <Input
                                                                                                name="checkout-email"
                                                                                                type="text"
                                                                                                className="checkout-email"
                                                                                                placeholder='Enter your email'
                                                                                                value={payerInfos.email}
                                                                                                style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                valid={false}
                                                                                                invalid={false}
                                                                                            />

                                                                                            <FormFeedback>
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mx-2">
                                                                                            <Label for='checkout-phone' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px" }}>Phone</span>
                                                                                                <IconToExplain popId="checkout-phone-pop" content="The payer phone" direction="right" />
                                                                                            </Label>
                                                                                            <Input
                                                                                                name="checkout-phone"
                                                                                                type="text"
                                                                                                className="checkout-phone"
                                                                                                placeholder='Enter your phone'
                                                                                                value={payerInfos.phone}
                                                                                                style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                valid={false}
                                                                                                invalid={false}

                                                                                            />

                                                                                            <FormFeedback>
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>

                                                                                        {optionalFields.map((field: any, index: any) => (
                                                                                            <FormGroup className="mx-2">
                                                                                                <Label for={`checkout_${field.label.replace(/\s+/g, "_")}`} className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                    <span className='' style={{ marginRight: "5px", textTransform: "capitalize" }}>{field.label}</span>
                                                                                                    <IconToExplain popId={`checkout-${field.label.replace(/\s+/g, "_")}-pop`} content={field.description} direction="right" />
                                                                                                    {field.required && <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>}
                                                                                                </Label>
                                                                                                {
                                                                                                    field.type === "text" ?
                                                                                                        <Input
                                                                                                            name={`checkout${field.label}`}
                                                                                                            type={field.characterLimits > 20 ? "textarea" : "text"}
                                                                                                            className={`checkout${field.label}`}
                                                                                                            id={`checkout${field.label}`}
                                                                                                            placeholder='Enter ...'
                                                                                                            //onChange={handlePaylinkDetailsInputChange}
                                                                                                            rows={4}
                                                                                                            maxLength={field.characterLimits}
                                                                                                            style={{ height: "3vh", fontSize: "0.75rem" }}
                                                                                                        />
                                                                                                        : field.type === "checkbox" ?
                                                                                                            <Row>
                                                                                                                {field.options &&
                                                                                                                    field.options.map((cb_option: any, index: any) =>
                                                                                                                        <Col xs="auto">
                                                                                                                            <FormGroup
                                                                                                                                check
                                                                                                                                inline
                                                                                                                            >
                                                                                                                                <Input
                                                                                                                                    style={{ cursor: "pointer" }}
                                                                                                                                    type="checkbox"
                                                                                                                                />
                                                                                                                                <Label check style={{ fontWeight: "bold", }}>
                                                                                                                                    {cb_option}
                                                                                                                                </Label>
                                                                                                                            </FormGroup>
                                                                                                                        </Col>
                                                                                                                    )
                                                                                                                }
                                                                                                            </Row>
                                                                                                            : field.type === "dropdownlist" ?
                                                                                                                <Row>
                                                                                                                    {field.displayAsRadiobutton && field.options &&
                                                                                                                        field.options.map((cb_option: any, index: any) =>
                                                                                                                            <Col xs="auto">
                                                                                                                                <FormGroup check inline>
                                                                                                                                    <Input
                                                                                                                                        style={{ cursor: "pointer" }}
                                                                                                                                        type="radio"
                                                                                                                                        name={field.label}
                                                                                                                                    />
                                                                                                                                    <Label check style={{ fontWeight: "bold" }}>
                                                                                                                                        {cb_option}
                                                                                                                                    </Label>
                                                                                                                                </FormGroup>
                                                                                                                            </Col>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {!field.displayAsRadiobutton && field.options &&
                                                                                                                        <Input
                                                                                                                            type="select"
                                                                                                                            className='mt-2'
                                                                                                                        >
                                                                                                                            {
                                                                                                                                field.options.map((cb_option: any, index: any) => <option value={cb_option}>{cb_option}</option>)
                                                                                                                            }
                                                                                                                        </Input>
                                                                                                                    }
                                                                                                                </Row>
                                                                                                                : <></>}
                                                                                                <FormFeedback FormFeedback >
                                                                                                    <TiWarningOutline color='#DF1B41' />
                                                                                                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                </FormFeedback>
                                                                                            </FormGroup>
                                                                                        ))}
                                                                                        <FormGroup className="mx-2">
                                                                                            <Label for='checkout-commentary' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                <span className='' style={{ marginRight: "5px" }}>Commentary</span>
                                                                                                <IconToExplain popId="checkout-commentary-pop" content="you can leave here a comment to the merchat" direction="right" />
                                                                                            </Label>
                                                                                            <Input
                                                                                                name="checkout-commentary"
                                                                                                type="textarea"
                                                                                                className="checkout-commentary"
                                                                                                id="checkout-description"
                                                                                                placeholder='Leave comment.'
                                                                                                //onChange={handlePaylinkDetailsInputChange}
                                                                                                rows={3}
                                                                                                value={payerInfos.commentary}
                                                                                                style={{ fontSize: "0.75rem" }}
                                                                                            />

                                                                                            <FormFeedback>
                                                                                                <TiWarningOutline color='#DF1B41' />
                                                                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                            </FormFeedback>
                                                                                        </FormGroup>
                                                                                        <Button
                                                                                            className="button-without-icon mx-2 my-5 float-start"
                                                                                            //disabled={carryOnToPayment()}
                                                                                            style={{
                                                                                                borderRadius: "4px",
                                                                                                //width: "100%",
                                                                                            }}
                                                                                        >
                                                                                            Donate
                                                                                        </Button>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    ) : selectedPaylinkType === "event-payment" ?
                                                                        (
                                                                            <>
                                                                                <div style={{ position: 'relative', width: '100%', height: '10vh' }}>
                                                                                    {
                                                                                        (selectedProducts.length > 0 && selectedProducts[0].picture) || photoEvent ?
                                                                                            <>
                                                                                                <img
                                                                                                    src={selectedProducts.length > 0 && selectedProducts[0].picture ? selectedProducts[0].picture : (photoEvent ? photoEvent : emptyProductImg)}
                                                                                                    alt="Placeholder"
                                                                                                    style={{ width: "100%", height: "10vh", objectFit: 'cover' }}
                                                                                                />
                                                                                            </> :
                                                                                            <>
                                                                                                <img
                                                                                                    src={selectedProducts.length > 0 && selectedProducts[0].picture ? selectedProducts[0].picture : ""}
                                                                                                    alt="Product"
                                                                                                    style={{ display: 'none' }}
                                                                                                />
                                                                                                <div
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: "10vh",
                                                                                                        backgroundColor: selectedProducts.length > 0 && selectedProducts[0].picture ? 'transparent' : '#BCBCBC',
                                                                                                        display: 'flex',
                                                                                                        justifyContent: 'center',
                                                                                                        alignItems: 'center',
                                                                                                        color: 'white',
                                                                                                        fontSize: '1.5rem',
                                                                                                        objectFit: 'cover',
                                                                                                        textAlign: 'center'
                                                                                                    }}
                                                                                                >
                                                                                                    {selectedProducts.length === 0 || !selectedProducts[0].picture ? "Event Cover" : ""}
                                                                                                </div>

                                                                                            </>
                                                                                    }
                                                                                    {/* <img
                                                                                        src={selectedProducts.length > 0 && selectedProducts[0].picture ? selectedProducts[0].picture : (photoEvent ? photoEvent : emptyProductImg)}
                                                                                        alt="Placeholder"
                                                                                        style={{ width: "100%", height: "10vh", objectFit: 'cover' }}
                                                                                    /> */}
                                                                                    <div className="d-flex justify-content-end" style={{ position: 'absolute', top: 0, right: 0, marginTop: '10px', marginRight: "20px" }}>
                                                                                        <span className="payment-step-text payment-step-text--white">Etape 1/2</span>
                                                                                    </div>
                                                                                </div>
                                                                                <Row className="m-4">
                                                                                    <Col xs="auto">
                                                                                        <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "15px", padding: "4px" }}>
                                                                                            <img
                                                                                                className="merchant-logo-img"
                                                                                                src={user && user.logo ? user.logo : emptyMerchantImg}
                                                                                                width="30px"
                                                                                                height="30px"
                                                                                                style={{ borderRadius: "10px" }}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="d-flex align-items-end py-2">
                                                                                        <span className="merchant-name" style={{ fontSize: "1.5rem", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                                                                            {user && user.company}
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{ marginBottom: "20%" }}>
                                                                                    <Col>
                                                                                        <Row className='text-start mx-4' >
                                                                                            <p className='w-75' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                                                                                {eventSingleMoreChoice == "one" && paylinkDetails.checkout_description}
                                                                                                {eventSingleMoreChoice == "more" && selectedProducts.length > 0 && selectedProducts[0].description}
                                                                                            </p>
                                                                                        </Row>
                                                                                        <hr
                                                                                            className="my-1"
                                                                                            style={{ marginLeft: "8%", width: "84%" }}
                                                                                        />
                                                                                        <Row className="j-c-center mx-4">
                                                                                            <Col
                                                                                                xs={12}
                                                                                                className='d-flex text-start '
                                                                                            >
                                                                                                <span
                                                                                                    className="resulted-amount"
                                                                                                    style={{
                                                                                                        textAlign: "left",
                                                                                                        color: "#DAAD66",
                                                                                                        fontFamily: "Inter",
                                                                                                        fontSize: "1.5rem",
                                                                                                        fontStyle: "normal",
                                                                                                        fontWeight: "900",
                                                                                                        lineHeight: "normal",
                                                                                                    }}>
                                                                                                    {checkoutOrder.resultedAmount} <small>TND</small>
                                                                                                </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        {
                                                                                            eventSingleMoreChoice == "one" ?
                                                                                                <>
                                                                                                    <Row className="j-c-center mx-4 my-2">
                                                                                                        <span style={{
                                                                                                            fontFamily: "Inter",
                                                                                                            fontSize: "1rem",
                                                                                                            fontWeight: "600",
                                                                                                            lineHeight: "30px",
                                                                                                            textAlign: "left",
                                                                                                            color: "#034DA3"
                                                                                                        }}>Tickets : </span>
                                                                                                    </Row>
                                                                                                    <Row className='mx-4 my-2' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                                                                                        <Col xs={12}>
                                                                                                            <EventItem
                                                                                                                itemObj={{
                                                                                                                    "id": 848,
                                                                                                                    "label": paylinkDetails.checkout_title,
                                                                                                                    "description": "",
                                                                                                                    "price": paylinkDetails.checkout_price,
                                                                                                                }}
                                                                                                                itemFor="event"
                                                                                                                onQuantityChange={handleOptionQuantityChange}
                                                                                                                smallSize
                                                                                                            />
                                                                                                            <hr
                                                                                                                style={{
                                                                                                                    marginBottom: "10px",
                                                                                                                    marginTop: "10px",
                                                                                                                }}
                                                                                                                className=""
                                                                                                            />
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    {
                                                                                                        extraEventFields.length > 0 &&
                                                                                                        <>
                                                                                                            <Row className="j-c-center mx-4 my-3">
                                                                                                                <span style={{
                                                                                                                    fontFamily: "Inter",
                                                                                                                    fontSize: "1rem",
                                                                                                                    fontWeight: "600",
                                                                                                                    lineHeight: "30px",
                                                                                                                    textAlign: "left",
                                                                                                                    color: "#034DA3"
                                                                                                                }}>Extra</span>
                                                                                                            </Row>
                                                                                                            {
                                                                                                                extraEventFields.map((extra: any) =>

                                                                                                                    <Row className='mx-4 my-2' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                                                                                                        <Col xs={12}>
                                                                                                                            <EventItem
                                                                                                                                itemObj={{
                                                                                                                                    "id": 848,
                                                                                                                                    "label": extra.label,
                                                                                                                                    "description": "",
                                                                                                                                    "price": extra.price,
                                                                                                                                }}
                                                                                                                                itemFor="extra"
                                                                                                                                onQuantityChange={handleExtrasQuantityChange}
                                                                                                                                smallSize
                                                                                                                            />
                                                                                                                            <hr
                                                                                                                                style={{
                                                                                                                                    marginBottom: "10px",
                                                                                                                                    marginTop: "10px",
                                                                                                                                }}
                                                                                                                                className="mx-2"
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                )
                                                                                                            }


                                                                                                        </>
                                                                                                    }

                                                                                                </> :
                                                                                                <>
                                                                                                    {
                                                                                                        checkoutOrder.products && checkoutOrder.products.length > 0 && <>
                                                                                                            <Row className="j-c-center mx-4 my-2">
                                                                                                                <span style={{
                                                                                                                    fontFamily: "Inter",
                                                                                                                    fontSize: "1rem",
                                                                                                                    fontWeight: "600",
                                                                                                                    lineHeight: "30px",
                                                                                                                    textAlign: "left",
                                                                                                                    color: "#034DA3"
                                                                                                                }}>{selectedProducts.length > 0 && selectedProducts[0].label}</span>
                                                                                                            </Row>
                                                                                                            {
                                                                                                                selectedProducts[0].options[0].option_values.map((ov: any, index: any) => {
                                                                                                                    const products = checkoutOrder.products;
                                                                                                                    let checkout_ov;

                                                                                                                    return (
                                                                                                                        <Row className='mx-4 my-2' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                                                                                                            <Col xs={12}>
                                                                                                                                <EventItem
                                                                                                                                    itemObj={ov}
                                                                                                                                    itemFor="event"
                                                                                                                                    onQuantityChange={handleOptionQuantityChange}
                                                                                                                                    smallSize
                                                                                                                                />
                                                                                                                                <hr
                                                                                                                                    style={{
                                                                                                                                        marginBottom: "10px",
                                                                                                                                        marginTop: "10px",
                                                                                                                                    }}
                                                                                                                                    className=""
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    )
                                                                                                                }
                                                                                                                )
                                                                                                            }
                                                                                                            {
                                                                                                                selectedProducts[0].options[1] &&
                                                                                                                <Row className="j-c-center mx-4 my-3">
                                                                                                                    <span style={{
                                                                                                                        fontFamily: "Inter",
                                                                                                                        fontSize: "1rem",
                                                                                                                        fontWeight: "600",
                                                                                                                        lineHeight: "30px",
                                                                                                                        textAlign: "left",
                                                                                                                        color: "#034DA3"
                                                                                                                    }}>Extra</span>
                                                                                                                </Row>
                                                                                                            }
                                                                                                            {
                                                                                                                selectedProducts[0].options[1] && selectedProducts[0].options[1].option_values.map((ov: any, index: any) =>
                                                                                                                    <Row className='mx-4 my-2' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                                                                                                        <Col xs={12}>
                                                                                                                            <EventItem
                                                                                                                                itemObj={ov}
                                                                                                                                itemFor="extra"
                                                                                                                                onQuantityChange={handleExtrasQuantityChange}
                                                                                                                                smallSize
                                                                                                                            />
                                                                                                                            <hr
                                                                                                                                style={{
                                                                                                                                    marginBottom: "10px",
                                                                                                                                    marginTop: "10px",
                                                                                                                                }}
                                                                                                                                className="mx-2"
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                )
                                                                                                            }
                                                                                                            <Row className="mx-4 d-flex justify-content-between">
                                                                                                                <Col sm={3}>
                                                                                                                    <span style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "20px", color: "#515151" }}>Total</span>
                                                                                                                </Col>
                                                                                                                <Col sm={2}>
                                                                                                                    <span
                                                                                                                        className="label"
                                                                                                                        style={{ color: "#515151", fontSize: "0.5rem", fontWeight: "600", fontFamily: "Inter", lineHeight: "18px" }}
                                                                                                                    >
                                                                                                                        {checkoutOrder.resultedAmount} TND
                                                                                                                    </span>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                        }


                                                                                    </Col>
                                                                                    <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                                                                        <Row className='my-3 mx-5' style={{ overflowY: "auto", maxHeight: "800px", overflowX: "hidden" }}>
                                                                                            <FormGroup className="mx-2">
                                                                                                <Label for='checkout-firstname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                    <span className='' style={{ marginRight: "5px" }}>First name</span>
                                                                                                    <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                                </Label>
                                                                                                <Input
                                                                                                    name="checkout-firstname"
                                                                                                    type="text"
                                                                                                    className="checkout-firstname"
                                                                                                    placeholder='Enter your firstname'
                                                                                                    value={payerInfos.firstname}
                                                                                                    style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                    valid={false}
                                                                                                    invalid={false}
                                                                                                />

                                                                                                <FormFeedback>
                                                                                                    <TiWarningOutline color='#DF1B41' />
                                                                                                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                </FormFeedback>
                                                                                            </FormGroup>
                                                                                            <FormGroup className="mx-2">
                                                                                                <Label for='checkout-lastname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                    <span className='' style={{ marginRight: "5px" }}>Last name</span>
                                                                                                    <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                                </Label>
                                                                                                <Input
                                                                                                    name="checkout-lastname"
                                                                                                    type="text"
                                                                                                    className="checkout-lastname"
                                                                                                    placeholder='Enter your lastname'
                                                                                                    value={payerInfos.lastname}
                                                                                                    style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                    valid={false}
                                                                                                    invalid={false}
                                                                                                />

                                                                                                <FormFeedback>
                                                                                                    <TiWarningOutline color='#DF1B41' />
                                                                                                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                </FormFeedback>
                                                                                            </FormGroup>
                                                                                            <FormGroup className="mx-2">
                                                                                                <Label for='checkout-email' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                    <span className='' style={{ marginRight: "5px" }}>Email</span>
                                                                                                    <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                                </Label>
                                                                                                <Input
                                                                                                    name="checkout-email"
                                                                                                    type="text"
                                                                                                    className="checkout-email"
                                                                                                    placeholder='Enter your email'
                                                                                                    value={payerInfos.email}
                                                                                                    style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                    valid={false}
                                                                                                    invalid={false}
                                                                                                />

                                                                                                <FormFeedback>
                                                                                                    <TiWarningOutline color='#DF1B41' />
                                                                                                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                </FormFeedback>
                                                                                            </FormGroup>
                                                                                            <FormGroup className="mx-2">
                                                                                                <Label for='checkout-phone' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                    <span className='' style={{ marginRight: "5px" }}>Phone</span>
                                                                                                    <span className="mx-1" style={{ color: "red" }}>*</span>
                                                                                                </Label>
                                                                                                <Input
                                                                                                    name="checkout-phone"
                                                                                                    type="text"
                                                                                                    className="checkout-phone"
                                                                                                    placeholder='Enter your phone'
                                                                                                    value={payerInfos.phone}
                                                                                                    style={{ width: "100%", height: "3vh", fontSize: "0.75rem" }}
                                                                                                    valid={false}
                                                                                                    invalid={false}

                                                                                                />

                                                                                                <FormFeedback>
                                                                                                    <TiWarningOutline color='#DF1B41' />
                                                                                                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                </FormFeedback>
                                                                                            </FormGroup>

                                                                                            {optionalFields.map((field: any, index: any) => (
                                                                                                <FormGroup className="mx-2">
                                                                                                    <Label for={`checkout_${field.label.replace(/\s+/g, "_")}`} className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                        <span className='' style={{ marginRight: "5px", textTransform: "capitalize" }}>{field.label}</span>
                                                                                                        <IconToExplain popId={`checkout-${field.label.replace(/\s+/g, "_")}-pop`} content={field.description} direction="right" />
                                                                                                        {field.required && <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>}
                                                                                                    </Label>
                                                                                                    {
                                                                                                        field.type === "text" ?
                                                                                                            <Input
                                                                                                                name={`checkout${field.label}`}
                                                                                                                type={field.characterLimits > 20 ? "textarea" : "text"}
                                                                                                                className={`checkout${field.label}`}
                                                                                                                id={`checkout${field.label}`}
                                                                                                                placeholder='Enter ...'
                                                                                                                //onChange={handlePaylinkDetailsInputChange}
                                                                                                                rows={4}
                                                                                                                maxLength={field.characterLimits}
                                                                                                                style={{ height: "3vh", fontSize: "0.75rem" }}
                                                                                                            />
                                                                                                            : field.type === "checkbox" ?
                                                                                                                <Row>
                                                                                                                    {field.options &&
                                                                                                                        field.options.map((cb_option: any, index: any) =>
                                                                                                                            <Col xs="auto">
                                                                                                                                <FormGroup
                                                                                                                                    check
                                                                                                                                    inline
                                                                                                                                >
                                                                                                                                    <Input
                                                                                                                                        style={{ cursor: "pointer" }}
                                                                                                                                        type="checkbox"
                                                                                                                                    />
                                                                                                                                    <Label check style={{ fontWeight: "bold", }}>
                                                                                                                                        {cb_option}
                                                                                                                                    </Label>
                                                                                                                                </FormGroup>
                                                                                                                            </Col>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </Row>
                                                                                                                : field.type === "dropdownlist" ?
                                                                                                                    <Row>
                                                                                                                        {field.displayAsRadiobutton && field.options &&
                                                                                                                            field.options.map((cb_option: any, index: any) =>
                                                                                                                                <Col xs="auto">
                                                                                                                                    <FormGroup check inline>
                                                                                                                                        <Input
                                                                                                                                            style={{ cursor: "pointer" }}
                                                                                                                                            type="radio"
                                                                                                                                            name={field.label}
                                                                                                                                        />
                                                                                                                                        <Label check style={{ fontWeight: "bold" }}>
                                                                                                                                            {cb_option}
                                                                                                                                        </Label>
                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {!field.displayAsRadiobutton && field.options &&
                                                                                                                            <Input
                                                                                                                                type="select"
                                                                                                                                className='mt-2'
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    field.options.map((cb_option: any, index: any) => <option value={cb_option}>{cb_option}</option>)
                                                                                                                                }
                                                                                                                            </Input>
                                                                                                                        }
                                                                                                                    </Row>
                                                                                                                    : <></>}
                                                                                                    <FormFeedback FormFeedback >
                                                                                                        <TiWarningOutline color='#DF1B41' />
                                                                                                        <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                    </FormFeedback>
                                                                                                </FormGroup>
                                                                                            ))}
                                                                                            <FormGroup className="mx-2">
                                                                                                <Label for='checkout-commentary' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", fontSize: "0.75rem" }}>
                                                                                                    <span className='' style={{ marginRight: "5px" }}>Commentary</span>
                                                                                                    <IconToExplain popId="checkout-commentary-pop" content="you can leave here a comment to the merchat" direction="right" />
                                                                                                </Label>
                                                                                                <Input
                                                                                                    name="checkout-commentary"
                                                                                                    type="textarea"
                                                                                                    className="checkout-commentary"
                                                                                                    id="checkout-description"
                                                                                                    placeholder='Leave comment.'
                                                                                                    //onChange={handlePaylinkDetailsInputChange}
                                                                                                    rows={3}
                                                                                                    value={payerInfos.commentary}
                                                                                                    style={{ fontSize: "0.75rem" }}
                                                                                                />

                                                                                                <FormFeedback>
                                                                                                    <TiWarningOutline color='#DF1B41' />
                                                                                                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>This input is invalid.</span>
                                                                                                </FormFeedback>
                                                                                            </FormGroup>
                                                                                            <Button
                                                                                                className="button-without-icon mx-2 my-5 float-start"
                                                                                                //disabled={carryOnToPayment()}
                                                                                                style={{
                                                                                                    borderRadius: "4px",
                                                                                                    //width: "100%",
                                                                                                }}
                                                                                            >
                                                                                                Continuer vers le paiement
                                                                                            </Button>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        ) : (<></>)
                                                    }
                                                </>
                                            ) : activeTab === "2" ? (
                                                <>
                                                    <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                                        <span className="payment-step-text payment-step-text--gray">Etape 2/2</span>
                                                    </div>
                                                    <Row>
                                                        <Col>
                                                            <Row className="m-5">
                                                                <Col xs="auto">
                                                                    <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                                                        <img
                                                                            className="merchant-logo-img"
                                                                            src={companyLogo ? companyLogo : emptyMerchantImg}
                                                                            width="40px"
                                                                            height="40px"
                                                                            style={{ borderRadius: "10px" }}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className="d-flex align-items-end py-2">
                                                                    <span className="merchant-name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                                                        {user && user.company}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <Row className='text-start mx-5' >
                                                                <p className='w-75' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                                                    Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet, consectetuer adipiscing
                                                                </p>
                                                            </Row>
                                                            <hr
                                                                className="my-2 mx-5"
                                                                style={{ width: "80%" }}
                                                            />

                                                            <Row>
                                                                <Col sm={12}>
                                                                    <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> First Name : Ahmed </span>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> Last Name : Mohsen </span>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> E-mail : Ahmedmohsen@gmail.com </span>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> Phone : Ahmedmohsen@gmail.com </span>
                                                                </Col>
                                                            </Row>
                                                            <hr
                                                                className="my-2 mx-5"
                                                                style={{ width: "80%" }}
                                                            />
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> 2 loom box </span>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> 4loom pass </span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                                            <iframe
                                                                src="https://app.paymee.tn/gateway/d58675000e81e8b0ad0134d3d30bffda/"
                                                                width="50%"
                                                                height="700px"
                                                                allowTransparency={true}
                                                                frameBorder={0}
                                                                style={{ backgroundColor: "#FFFFFF", overflow: "hidden" }}
                                                            ></iframe>
                                                        </Col>
                                                    </Row>

                                                </>
                                            ) : activeTab === "3" ? (
                                                <>
                                                    <>
                                                        <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                                            <span className="payment-step-text payment-step-text--gray">Etape 2/2</span>
                                                        </div>
                                                        <Row className='my-4' style={{ height: "1000px" }}>
                                                            <Col sm={12}>
                                                                <div>
                                                                    <div className="card-body">
                                                                        <div className="row j-c-center" style={{ display: "none" }}>
                                                                            <div className="col-xs-12 mt-20">
                                                                                <button
                                                                                    className="rounded payment-btn"
                                                                                    data-toggle="modal"
                                                                                    data-target="#modal-payment"
                                                                                    data-backdrop="static"
                                                                                    data-keyboard="false"
                                                                                >
                                                                                    <span
                                                                                        style={{ fontSize: "18px", fontWeight: "bold" }}
                                                                                    >
                                                                                        Accéder au paiement
                                                                                    </span>
                                                                                    <img
                                                                                        src={edinarPaymeeVisaMc}
                                                                                        alt="Paymee Visa MasterCard"
                                                                                    />
                                                                                </button>
                                                                                <form
                                                                                    method="post"
                                                                                    action="https://app.paymee.tn/gateway/b2cb7bbb20be7f04d3e81fbbc9974423/"
                                                                                >
                                                                                    <input
                                                                                        type="hidden"
                                                                                        name="url_ok"
                                                                                        value="https://paylink.tn/3103/check"
                                                                                    />
                                                                                    <input
                                                                                        type="hidden"
                                                                                        name="url_ko"
                                                                                        value="https://paylink.tn/failure"
                                                                                    />
                                                                                    <button className="rounded payment-btn">
                                                                                        <span
                                                                                            style={{ fontSize: "18px", fontWeight: "bold" }}
                                                                                        >
                                                                                            Accéder au paiement
                                                                                        </span>
                                                                                        <img
                                                                                            src={edinarPaymeeVisaMc}
                                                                                            alt="Paymee Visa MasterCard"
                                                                                        />
                                                                                    </button>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                        <div className='shadow-sm p-3  bg-white rounded mt-2' style={{ marginLeft: "25%", marginRight: "25%" }}>
                                                                            <div className="card-body">
                                                                                <div className='text-center ' style={{ color: "#258750", fontSize: "28px", fontFamily: "Inter", fontWeight: "700" }}>Paiement réussi !</div>
                                                                                <div className='text-center '>
                                                                                    <span style={{ color: "#BCBCBC", fontWeight: '400', fontFamily: "Inter" }}>{paylinkDetails.checkout_custom_returnmsg}</span>
                                                                                </div>
                                                                                <div className='text-center'>
                                                                                    <img src={successImg}></img>
                                                                                </div>
                                                                                <div className='mt-2 ' >
                                                                                    <PButton size='lg' type="submit"
                                                                                        label={paylinkDetails.checkout_redirect_url ? `Continuer (redirected to: ${paylinkDetails.checkout_redirect_url})` : "Continuer"}
                                                                                    //onClick={goToPreviousPage}
                                                                                    ></PButton>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>

                                                </>
                                            ) : (<></>)
                                    }
                                    <div style={{ position: "absolute", bottom: "0", marginBottom: "2%", left: "0", right: "0" }}>
                                        <div className="text-center mt-5">
                                            <span><FaLock color="#00FF00" /> <span style={{ color: "#BCBCBC", fontWeight: '400', fontFamily: "Inter" }}>Le paiement par carte bancaire est géré par la Société Monétique de Tunisie</span></span>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <span style={{ fontSize: "12px" }}>Crée par</span>
                                            <img style={{ marginLeft: "15px" }} src={logo} height={25} width={85}></img>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Col >
            </Row >

        </>
    );
};

export default CheckoutPreview;
