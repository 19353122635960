import { baseGateway as axios } from './base'


export const initiatePayment = async (payment) => {
    const payment_payload = {
        "amount": payment.amount,
        "note": payment.description,
        "first_name": payment.first_name,
        "last_name": payment.last_name,
        "email": payment.email,
        "phone": payment.phone,
        "return_url": "https://preprod.paymee.app/gateway/success",
        "cancel_url": "https://preprod.paymee.app/gateway/failure",
        "webhook_url": "https://www.google.com/"
    }
    return await axios.post('payment_initialization', payment_payload);
}

export const confirmPayment = async (orderId, signature) => {
    return await axios.post('confirm_cb_payment?orderId=' + orderId + '&sign=' + signature)
}

export const loadPayment = async (payload) => {
    return await axios.post('load_payment', payload)
}