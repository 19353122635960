import React, { startTransition, useEffect, useState } from "react";
import {
  createBankAccount,
  deactivateBankAccount,
  getAllBankAccounts,
  getBankAccountDetails,
  updateBankAccount,
} from "../../services/api/panormix";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  FaCheckCircle,
  FaEdit,
  FaPlus,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import emptyTableImage from "../../assets/images/empty-table.svg";
import SelectBox from "../../components/form/input/SelectBox";
import PTable from "../../components/table/Table";
import { Layout as DashboardLayout } from "../../containers/Layout";
import PInput from "../../components/form/input/Input";
import { GrClose } from "react-icons/gr";
import "../../scss/pages/bank-account.css";
import { formatDate, formatDatetime, isObjectEmpty } from "../../utils/helper";
import { IoMdArrowBack } from "react-icons/io";
import { Navigate, useNavigate } from "react-router-dom";
import { FiEdit, FiFilter, FiSearch } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import PModal from "../../components/ui/Modal/Modal";
import { phoneNumberPattern } from "../../utils/patterns";
import PConfirmModal from "../../components/ui/Modal/ConfirmModal";
import { DotLoader } from "react-spinners";
import PSearchBar from "../../components/form/input/SearchBar";
import PDatePicker from "../../components/form/input/DatePicker";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/ui/pagination/Pagination";
import MultiRangeSlider from "../../components/MultiRangeSlider/MultiRangeSlider";
import { ImListNumbered } from "react-icons/im";
import { BsSliders } from "react-icons/bs";
import { ShowHideCan } from "../../utils/permissions";
import { useSelector } from "react-redux";
import imgEmptyPage from "../../assets/images/bankAccount_notFound.png";
import Select from "react-select";

type BankAccountDetails = {
  id: string;
  label: string;
  bic: string;
  rib: string;
  phone_number: string;
};

const initialBankAccountDetails: BankAccountDetails = {
  id: "",
  label: "",
  bic: "",
  rib: "",
  phone_number: "",
};

const BankAccounts = ({ actualTabs }: any) => {
  const ribNumberPattern = /^\d{20,20}$/;
  const { t } = useTranslation("banckAccount");

  const [bankAccountListUpdated, setBankAccountListUpdated] =
    useState<boolean>(false);

  const [data, setData] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);

  const [bankAccountDetails, setBankAccountDetails] =
    useState<any>(initialBankAccountDetails);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [bankAccountDetailsModal, setBankAccountDetailsModal] = useState(false);

  const [formSubmitted, setFormIsSubmited] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const actualDate = new Date();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterBydate, setfilterBydate] = useState(false);

  const [selectedBankAccount, setSelectedBankAccount] = useState({
    id: "",
    bank: "",
    rib: "",
    phone_number: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [error, setError] = useState<string>("");
  const [errors, setErrors] = useState({
    label: "",
    bic: "",
    rib: "",
    phone_number: "",
  });

  const [modalFilter, setModalFilter] = useState(false);
  const [showAmountFilter, setShowAmountFilter] = useState(false);
  const [filterAmount, setFilterAmount] = useState("");
  const [validateFilter, setValidateFilter] = useState(false);
  const [objFilter, setObjFilter] = useState({
    startDate: "",
    endDate: "",
    bank: "",
    query: "",
  });

  const bankAccountColumns = [
    { index: 1, label: t("name"), accessor: "label" },
    { index: 2, label: t("banckAccount"), accessor: "rib" },
    { index: 3, label: t("bank"), accessor: "bic" },
    { index: 4, label: t("numTel"), accessor: "phone_number" },
    { index: 5, label: t("createdBy"), accessor: "created_by" },
    { index: 6, label: t("dateAdded"), accessor: "date_added" },
    { index: 7, label: "", accessor: "actions" },
  ];
  const { i18n } = useTranslation();
  const [showResetButton, setShowResetButton] = useState(false);

  const chosenCompany = useSelector(
    (state: any) => state.company.chosenCompany
  );
  const profile = useSelector((state: any) => state.profile);
  const permissions = useSelector((state: any) => state.permissions);

  useEffect(() => {
    if (profile != null) {
      if (
        profile.status === "VERIFIED" &&
        permissions.some(
          (permission: any) =>
            permission.module === "WIRES" &&
            permission.tag === "view_bank_accounts"
        )
      ) {
        getAllBankAccounts(objFilter, currentPage)
          .then((res) => {
            setFetchingData(false);

            const rows: any = [];
            const filtered = res.data.bank_accounts;
            setTotalItems(res.data.total);
            setData(filtered);
            filtered.map((bank_account: any) => {
              const w = {
                label: bank_account.label,
                bic: bank_account.bic,
                rib: bank_account.rib,
                phone_number: bank_account.phone,
                created_by:
                  bank_account.created_by.first_name +
                  " " +
                  bank_account.created_by.last_name,
                active: bank_account.active ? (
                  <Badge color="success">
                    <FaCheckCircle size={16} /> Active
                  </Badge>
                ) : (
                  <Badge
                    color="warning"
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "20px" }}
                  >
                    <FaCheckCircle size={16} /> Inactive{" "}
                  </Badge>
                ),
                date_added: formatDatetime(bank_account.date_added),
                actions: (
                  <>
                    {bank_account.wire_count == 0 && (
                      <ShowHideCan I="delete_bank_accounts" a="WIRES">
                        <IoTrashOutline
                          key={bank_account.id}
                          onClick={() => {
                            handleOpenDeleteModal(bank_account.id);
                          }}
                          style={{
                            color: "#B42C2C",
                            cursor: "pointer",
                            padding: "0px",
                            marginLeft: "5px",
                          }}
                        ></IoTrashOutline>
                      </ShowHideCan>
                    )}
                  </>
                ),
              };
              rows.push(w);
            });

            setRowsData(rows);
          })
          .catch((err) => {
            setFetchingData(false);
            if (err.response) {
              if (err.response.status === 500) {
                toast.error(
                  "Request Error: Error occured while trying to login"
                );
              }
            } else if (err.request) {
              toast.error("Network Error : no response from server");
            }
          });
      }
    }
  }, [
    validateFilter,
    searchTerm,
    filterBydate,
    bankAccountListUpdated,
    chosenCompany,
    actualTabs,
  ]);

  const toggleBankAccountModal = () => {
    setBankAccountModal(!bankAccountModal);
    setError("");
    setErrors({ label: "", bic: "", rib: "", phone_number: "" });
    setBankAccountDetails({
      id: "",
      label: "",
      bic: "",
      rib: "",
      phone_number: "",
    });
  };

  const toggleDeleteConfirmationModal = () =>
    setShowDeleteConfirm(!showDeleteConfirm);

  const toggleBankAccountEditModal = () =>
    setBankAccountDetailsModal(!bankAccountDetailsModal);

  const handleOpenDeleteModal = (bank_account_id: any) => {
    /* setSelectedBankAccount(
      data.filter((bank_account: any) => bank_account.id == bank_account_id)
    ); */
    setSelectedBankAccount({ ...selectedBankAccount, id: bank_account_id });
    toggleDeleteConfirmationModal();
  };

  const handleOpenEditModal = (bank_account_id: any) => {
    getBankAccountDetails(bank_account_id)
      .then((result) => {
        const bank_account = result.data.bank_account;

        setBankAccountDetails({
          id: bank_account.id,
          bic: bank_account.bic,
          rib: bank_account.rib,
          label: bank_account.label,
          phone_number: bank_account.phone,
        });

        toggleBankAccountEditModal();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error occured while trying to do the action");
      });
  };

  const handleChangeBankAccount = (event: any) => {
    const { name, value, type, checked } = event.target;
    
    setBankAccountDetails({
      ...bankAccountDetails,
      [name]: type === "checkbox" ? checked : value,
    });
    setFormIsSubmited(false);
    setErrors({
      label: "",
      bic: "",
      rib: "",
      phone_number: "",
    });
    setError("");
  };

  const handleAddBankAccount = (event: React.FormEvent<HTMLFormElement>) => {
    const { label, bic, rib } = bankAccountDetails;
    console.log("details:",bankAccountDetails)

    if (!label) {
      setErrors({ ...errors, label: "Veuillez saisir un nom" });
      return;
    }

    if (!bic) {
      setErrors({ ...errors, bic: "Veuillez choisir une banque" });
      return;
    }

    if (!rib) {
      setErrors({ ...errors, rib: "Veuillez entrer le Rib" });
      return;
    } else if (!ribNumberPattern.test(rib)) {
      setErrors({ ...errors, rib: "RIB doit comporter 20 chiffres" });
      return;
    }


    createBankAccount(bankAccountDetails)
      .then((res) => {
        toast.success("Compte bancaire créé avec succès");
        toggleBankAccountModal();
        setBankAccountListUpdated(!bankAccountListUpdated);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {
            setError(err.response.data.errors["rib"]);
          } else if (err.response.status === 500) {
            toast.error("Request Error: Error occured while trying to login");
          }
        } else if (err.request) {
          toast.error("Network Error : no response from server");
        } else {
          setError(err.message);
        }
      });
  };

  const handleEditBankAccount = (event: React.FormEvent<HTMLFormElement>) => {
    //event.preventDefault();
    setFormIsSubmited(true);
    const { id, label, phone_number } = bankAccountDetails;

    if (!label) {
      setErrors({ ...errors, label: "Veuillez entrer un nom." });
      return;
    }

    setIsLoading(true);

    updateBankAccount(id, {
      label: label,
      phone: phone_number,
    })
      .then((res) => {
        setIsLoading(false);
        toast.success("Compte bancaire mis à jour avec succès");
        setBankAccountListUpdated(!bankAccountListUpdated);
        toggleBankAccountEditModal();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            setError(err.response.data.errors[0]["bank_account"]);
          } else if (err.response.status === 500) {
            toast.error("Request Error: Error occured while trying to update");
          }
        } else if (err.request) {
          toast.error("Network Error : no response from server");
        } else {
          setError(err.message);
        }
      });
  };

  const handleDeleteBankAccount = (bank_account_id: any) => {
    setIsLoading(true);
    deactivateBankAccount(bank_account_id)
      .then((res) => {
        setIsLoading(false);
        toast.success("Le compte a été supprimé avec succès");
        setData((prevState: any) =>
          prevState.filter((ba: any) => ba.id !== bank_account_id)
        );
        setBankAccountListUpdated(!bankAccountListUpdated);
        toggleDeleteConfirmationModal();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error occured while trying to do the action");
      });
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevClick = () => setCurrentPage(currentPage - 1);

  const renderBankAccounts = () => {
    const columns = bankAccountColumns;

    return (
      <>
        <div
          className="my-3 d-flex justify-content-between"
          style={{ paddingRight: "0.5rem" }}
        >
          <Col xxl={5} xl={6} lg={8} md={8} sm={8} xs={10}>
            <InputGroup>
              <InputGroupText style={{ backgroundColor: "white" }}>
                <FiSearch style={{ fontSize: "16px" }} />
              </InputGroupText>
              <input
                type="text"
                placeholder="Recherche par nom-prenom, n° de compte , n° de contact"
                style={{ height: "35px", width: "90%" }}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  objFilter.query = e.target.value;
                  setObjFilter({ ...objFilter });
                }}
              />
            </InputGroup>
          </Col>
          <Col xs="auto">
            {!isObjectEmpty(objFilter) && showResetButton && (
              <Button
                className="outlined-button-with-icon mx-2"
                onClick={() => {
                  setShowResetButton(false);
                  resetFilter();
                }}
              >
                {t("resetFilter")}
              </Button>
            )}
            <Button
              className="outlined-button-with-icon"
              onClick={toggleFilter}
              disabled={data.length == 0 && isObjectEmpty(objFilter)}
            >
              <FiFilter style={{ marginRight: "4px" }} />
              {t("filter")}
            </Button>
            <ShowHideCan I="create_bank_accounts" a="WIRES">
              <Button
                className="button-with-icon mx-2"
                onClick={toggleBankAccountModal}
              >
                <FaPlus style={{ marginRight: "8px" }} />
                Ajouter un compte bancaire
              </Button>
            </ShowHideCan>
          </Col>
        </div>
        <div
          className="my-3 row "
          style={{ marginLeft: "0px", overflowX: "auto" }}
        >
          <PTable columns={columns} data={rowsData} />
        </div>
        {isLoading ? (
          <>
            {" "}
            <Row
              className="justify-content-center"
              style={{ width: "100%", marginTop: "10%" }}
            >
              <DotLoader
                color="rgb(112, 157, 211)"
                cssOverride={{}}
                loading
                size={100}
                speedMultiplier={2}
              />
            </Row>
          </>
        ) : (
          <>
            {data.length === 0 && (
              <>
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ marginTop: "12%" }}
                >
                  <img
                    src={imgEmptyPage}
                    alt="Empty Table"
                    style={{ width: "250px", height: "250px" }}
                  />
                  {/* <span
                    className="mt-2"
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#034DA3",
                    }}
                  >
                    Aucun compte n'est trouvé
                  </span> */}
                </div>
              </>
            )}
            {data.length === 0 && !isObjectEmpty(objFilter) && (
              <>
                <Row className="mt-5 text-center">
                  <span>{t("find")}</span>
                </Row>
              </>
            )}

            {data.length > 0 && (
              <div className="justify-content-between d-flex ">
                <Col xs="4">
                  <span>
                    {rowsData.length} {t("lines")}
                  </span>
                </Col>
                <Col xs="auto">
                  <Pagination
                    onNextClick={handleNextClick}
                    onPrevClick={handlePrevClick}
                    nextLabel={t("next").toString()}
                    prevLabel={t("previous").toString()}
                    prevDisabled={currentPage === 1}
                    nextDisabled={
                      currentPage >= Math.ceil(totalItems / pageSize)
                    }
                  />
                </Col>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const toggleFilter = () => setModalFilter(!modalFilter);

  const handleStatusChange = (event: any) => {
    objFilter.bank = event.target.value;
    setObjFilter({ ...objFilter });
  };

  const resetFilter = () => {
    setSearchTerm(""), setStartDate(""), setEndDate("");
    objFilter.endDate = "";
    objFilter.startDate = "";
    objFilter.bank = "";
    setObjFilter({ ...objFilter });
    setValidateFilter(false);
  };
  const selectThemeColors = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#E3E8EE", // for option hover bg-color
      primary: "#d9dce1", // for selected option bg-color
      neutral10: "#7367f0", // for tags bg-color
      neutral20: "#d9dce1", // for input border-color
      neutral30: "#d9dce1", // for input hover border-color
    },
  });
  const banckOptions = [
    { value: "ABC", label: "ABC" },
    {
      value: "Al Wifak International Bank",
      label: "Al Wifak International Bank",
    },
    { value: "Al Baraka Bank", label: "Al Baraka Bank" },
    { value: "Amen Bank", label: "Amen Bank" },
    { value: "ATB", label: "ATB" },
    { value: "ATTIJARI", label: "ATTIJARI" },
    { value: "BFPME", label: "BFPME" },
    { value: "BFT", label: "BFT" },
    { value: "BH", label: "BH" },
    { value: "BIAT", label: "BIAT" },
    { value: "BNA", label: "BNA" },
    { value: "BT", label: "BT" },
    { value: "BTE", label: "BTE" },
    { value: "BTL", label: "BTL" },
    { value: "BTK", label: "BTK" },
    { value: "BTS", label: "BTS" },
    { value: "Citi Bank", label: "Citi Bank" },
    { value: "La Poste", label: "La Poste" },
    { value: "QNB", label: "QNB" },
    { value: "STB", label: "STB" },
    { value: "TSB", label: "TSB" },
    { value: "UBCI", label: "UBCI" },
    { value: "UIB", label: "UIB" },
    { value: "Zaitouna", label: "Zaitouna" },
  ];
  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      {/* <div
        className="bank_accounts-page"
        style={{
          marginTop: "1.5%",
          marginRight: "1.5%",
          marginLeft: "1%",
        }}
      > */}

      <Row>{renderBankAccounts()}</Row>
      {/* </div> */}

      <PModal
        isOpen={bankAccountModal}
        toggle={toggleBankAccountModal}
        onConfirm={handleAddBankAccount}
        title="Ajouter un compte bancaire"
        onConfirmText="Confirmer"
        onCancelText="Fermer"
        size="sm"
        error={error}
        texteAlign="center"
        marginLeft="8.5%"
        marginRight="8.5%"
        marginTop="-5%"
        marginBottom="3%"
      >
        <Form>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
              marginTop: "4%",
            }}
          >
            <FormGroup>
              <Label for="label" className="d-block">
                Libellé <span style={{ color: "red" }}>*</span>
              </Label>
              <PInput
                classname={`${
                  formSubmitted && (errors.label ? "has-error" : "has-success")
                }`}
                type="text"
                name="label"
                error={!!errors.label}
                errorMessage={errors.label}
                placeholder="Entrez libellé"
                value={bankAccountDetails.label}
                onChange={handleChangeBankAccount}
              />
            </FormGroup>
          </Row>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            <FormGroup>
              <Label for="bic" className="d-block">
                Banque <span style={{ color: "red" }}>*</span>
              </Label>
              {/* <SelectBox
                name="bic"
                onChange={handleChangeBankAccount}
                value={bankAccountDetails.bic}
                placeholder="Séléctionner"
                error={!!errors.bic}
                errorMessage={errors.bic}
                classname={`${formSubmitted && (errors.bic ? "has-error" : "has-success")
                  }`}
              >
                <option style={{color:"black"}}>ABC</option>
                <option style={{color:"black"}}>Al Wifak International Bank</option>
                <option style={{color:"black"}}>Al Baraka Bank</option>
                <option style={{color:"black"}}>Amen Bank</option>
                <option style={{color:"black"}}>ATB</option>
                <option style={{color:"black"}}>ATTIJARI</option>
                <option>BFPME</option>
                <option>BFT</option>
                <option>BH</option>
                <option>BIAT</option>
                <option>BNA</option>
                <option>BT</option>
                <option>BTE</option>
                <option>BTL</option>
                <option>BTK</option>
                <option>BTS</option>
                <option>Citi Bank</option>
                <option>La Poste</option>
                <option>QNB</option>
                <option>STB</option>
                <option>TSB</option>
                <option>UBCI</option>
                <option>UIB</option>
                <option>Zaitouna</option>
              </SelectBox> */}
              <Select
                id="bank"
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                placeholder="Selectionner"
                options={banckOptions}
                isSearchable={false}
                isClearable
                onChange={(selectedOption:any)=>{ const bankValue = selectedOption ? selectedOption.value : "";

                setBankAccountDetails({
                  ...bankAccountDetails,
                  bic: bankValue, // Assuming you're updating the bank field in bankAccountDetails
                });
              
                setFormIsSubmited(false);
                setErrors({
                  label: "",
                  bic: "",
                  rib: "",
                  phone_number: "",
                });
                setError("");}}

              />
            </FormGroup>
          </Row>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            <FormGroup>
              <Label for="rib" className="d-block">
                RIB <span style={{ color: "red" }}>*</span>
              </Label>
              <PInput
                classname={`${
                  formSubmitted && (errors.rib ? "has-error" : "has-success")
                }`}
                type="text"
                name="rib"
                error={!!errors.rib}
                errorMessage={errors.rib}
                placeholder="Entrez le RIB"
                value={bankAccountDetails.rib}
                onChange={handleChangeBankAccount}
              />
            </FormGroup>
          </Row>
          {/* <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
              marginBottom: "-5%"
            }}
          >
            <FormGroup>
              <Label for="phone_number" className="d-block">
                Téléphone
              </Label>
              <PInput
                classname={`${formSubmitted && (errors.label ? "has-error" : "has-success")
                  }`}
                type="tel"
                name="phone_number"
                error={!!errors.phone_number}
                errorMessage={errors.phone_number}
                placeholder="Entrez le numéro de téléphone"
                value={bankAccountDetails.phone_number}
                onChange={handleChangeBankAccount}
              />
            </FormGroup>
          </Row> */}
        </Form>
      </PModal>

      <PModal
        isOpen={bankAccountDetailsModal}
        toggle={toggleBankAccountEditModal}
        onConfirm={handleEditBankAccount}
        title="Edit bank account details"
        onConfirmText="Valider"
        onCancelText="Fermer"
        size="sm"
        error={error}
      >
        <Form>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
              marginTop: "24px",
            }}
          >
            <FormGroup>
              <Label for="label" className="d-block">
                Nom et prénom
              </Label>
              <PInput
                classname={`${
                  formSubmitted && (errors.label ? "has-error" : "has-success")
                }`}
                type="text"
                name="label"
                error={!!errors.label}
                errorMessage={errors.label}
                placeholder="Enter name"
                value={bankAccountDetails.label}
                onChange={handleChangeBankAccount}
              />
            </FormGroup>
          </Row>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            <FormGroup>
              <Label for="bic" className="d-block">
                Banque
              </Label>
              <PInput
                type="text"
                name="bic"
                value={bankAccountDetails.bic}
                disabled
              />
            </FormGroup>
          </Row>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            <FormGroup>
              <Label for="rib" className="d-block">
                RIB
              </Label>
              <PInput
                type="text"
                name="rib"
                value={bankAccountDetails.rib}
                disabled
              />
            </FormGroup>
          </Row>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            <FormGroup>
              <Label for="phone_number" className="d-block">
                Numéro de téléphone
              </Label>
              <PInput
                classname={`${
                  formSubmitted &&
                  (errors.phone_number ? "has-error" : "has-success")
                }`}
                type="tel"
                name="phone_number"
                error={!!errors.phone_number}
                errorMessage={errors.phone_number}
                placeholder="Enter phone number"
                value={bankAccountDetails.phone_number}
                onChange={handleChangeBankAccount}
              />
            </FormGroup>
          </Row>
        </Form>
      </PModal>

      <Modal
        isOpen={showDeleteConfirm}
        centered={true}
        fade={true}
        toggle={toggleDeleteConfirmationModal}
        style={{ width: "500px" }}
      >
        <ModalBody>
          <Row
            style={{
              marginLeft: "12px",
              marginRight: "24px",
              marginBottom: "4%",
            }}
          >
            <h6
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "29px",
                color: "#213A7D",
              }}
            >
              Voulez-vous supprimer le compte ?
            </h6>
          </Row>
          <Form>
            <Row
              style={{
                marginLeft: "24px",
                marginRight: "24px",
                marginBottom: "-2%",
              }}
            >
              <Button
                className="button-without-icon mb-2"
                onClick={() => handleDeleteBankAccount(selectedBankAccount.id)}
              >
                Supprimer
              </Button>
              <Button
                className="outlined-button-with-icon"
                onClick={toggleDeleteConfirmationModal}
                outline
              >
                <GrClose className="mx-2" />
                Annuler
              </Button>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="border-0"></ModalFooter>
      </Modal>

      <Modal
        isOpen={modalFilter}
        toggle={toggleFilter}
        style={{
          marginTop: "6.5%",
          marginRight: "2.2%",
          maxWidth: "60%",
        }}
        backdropClassName="blue-backdrop"
        backdrop={false}
        fade={false}
      >
        <ModalBody>
          <div className="row">
            <div className="col-6" style={{ borderRight: "2px #D9DCE1 inset" }}>
              <span>{t("filterByRange")}</span>
            </div>
            <div className="col-6">
              <span>{t("filterByOption")}</span>
            </div>
          </div>
          <div className="row">
            <div
              className="col-6 "
              style={{ borderRight: "2px #D9DCE1 inset" }}
            >
              <div className="row " style={{ marginTop: "5%" }}>
                <div className="col-6">
                  <FormGroup className="mb-3">
                    <Input
                      id="exampleDateFrom"
                      name="dateFrom"
                      placeholder="From"
                      type="date"
                      style={{ height: "40px" }}
                      value={
                        objFilter.startDate === ""
                          ? formatDate(actualDate)
                          : objFilter.startDate
                      }
                      onChange={(e) => {
                        (objFilter.startDate = e.target.value),
                          setObjFilter({ ...objFilter });
                      }}
                      max={actualDate.toISOString().split("T")[0]}
                    />
                  </FormGroup>
                </div>
                <div className="col-6">
                  <FormGroup className="mb-3">
                    <Input
                      id="exampleDateTo"
                      name="dateTo"
                      placeholder="To"
                      type="date"
                      style={{ height: "40px" }}
                      value={
                        objFilter.endDate === ""
                          ? formatDate(actualDate)
                          : objFilter.endDate
                      }
                      onChange={(e) => {
                        (objFilter.endDate = e.target.value),
                          setObjFilter({ ...objFilter }),
                          setfilterBydate(true);
                      }}
                      max={actualDate.toISOString().split("T")[0]}
                      min={objFilter.startDate}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row " style={{ marginTop: "5%" }}>
                <div className="col-1" style={{ marginTop: "1%" }}>
                  <span>{t("Bank")}</span>
                </div>
                <div className="col-3">
                  <Input
                    name="bank"
                    style={{ height: "35px" }}
                    onChange={handleStatusChange}
                    value={objFilter.bank}
                    type="select"
                  >
                    <option>ABC</option>
                    <option>Al Wifak International Bank</option>
                    <option>Al Baraka Bank</option>
                    <option>Amen Bank</option>
                    <option>ATB</option>
                    <option>ATTIJARI</option>
                    <option>BFPME</option>
                    <option>BFT</option>
                    <option>BH</option>
                    <option>BIAT</option>
                    <option>BNA</option>
                    <option>BT</option>
                    <option>BTE</option>
                    <option>BTL</option>
                    <option>BTK</option>
                    <option>BTS</option>
                    <option>Citi Bank</option>
                    <option>La Poste</option>
                    <option>QNB</option>
                    <option>STB</option>
                    <option>TSB</option>
                    <option>UBCI</option>
                    <option>UIB</option>
                    <option>Zaitouna</option>
                  </Input>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        {/* style={{ marginRight: "-6%" }} */}
        <ModalFooter>
          <div className="d-flex" style={{ marginRight: "-2%" }}>
            <div className="col-10">
              <span
                style={{
                  color: "#034DA3",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  resetFilter();
                }}
              >
                {t("resetFilter")}
              </span>
            </div>
            <div className="col-2">
              <Button
                style={{ backgroundColor: "#034DA3", marginRight: "5%" }}
                onClick={() => {
                  setShowResetButton(true);
                  setValidateFilter(true);
                  setModalFilter(!modalFilter);
                }}
              >
                {t("confirm")}
              </Button>
              <Button outline color="secondary" onClick={toggleFilter}>
                {t("cancel")}
              </Button>{" "}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default BankAccounts;
