import React, { useEffect, useRef, useState } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import {
  Badge,
  Button,
  Card,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { IoTicketOutline } from "react-icons/io5";
import { MdPhotoCamera, MdProductionQuantityLimits } from "react-icons/md";
import { CiDeliveryTruck } from "react-icons/ci";
import imageProduct from "../../assets/images/iconProduct.png";
import "../../scss/pages/compteUser.css";
import { TiPlus } from "react-icons/ti";
import { createProduct, editProduct } from "../../services/api/panormix";
import imageDelivery from "../../assets/images/delivery.png";
import imageEvent from "../../assets/images/event.png";
import { LoadingModal } from "../spinner/loading";
import { SuccessModal } from "../../components/ui/Modal/ModalSucess/ModalSucess";
import { useSelector } from "react-redux";
import DropZoneHolder from "../form/dropzone/DropZoneHolder";

type OptionsValue = {
  label: string;
  description: string;
  price: number;
  stock: number;
};
type Options = {
  label: string;
  description: string;
  type: string;
  hidden: boolean;
  option_values: any[];
  extra?: boolean;
  required: boolean;
};
type Product = {
  label: string;
  description: string;
  picture: string | ArrayBuffer;
  init_price: any;
  type: string;
  active: boolean;
  qty?: number;
  options: Options[] | [];
};

interface ModalType {
  // isOpen: boolean;
  // toggle: () => void;
  // newProduct?: Product;
  // mode?: string;
  // productTogglee: () => void;
  toggle: () => void;
  showModalFormProduct: boolean;
  onSendData: () => void;
  type: string;
}
const AddProduct: React.FC<ModalType> = (props: ModalType) => {
  const { showModalFormProduct, onSendData, type, toggle } = props;
  // const [listTypes, setListTypes] = useState([{ "label": "Case à coché", "value": "multiple" }, { "label": "Liste déroulante", "value": "select" }])
  const [photoProduct, setPhotoproduct] = useState<any>("");
  const option_value: OptionsValue = {
    label: "",
    description: "",
    price: 0,
    stock: 0,
  };
  const optionData: Options = {
    label: "",
    description: "",
    type: "select",
    hidden: false,
    option_values: [],
    required: true,
  };
  const optionDataExtra: Options = {
    label: "",
    description: "",
    type: "",
    hidden: false,
    option_values: [],
    extra: true,
    required: true,
  };
  const productData: Product = {
    label: "",
    description: "",
    picture: "",
    init_price: 0,
    type: "",
    qty: 0,
    active: false,
    options: [],
  };
  const validFormOptionValue = {
    label: false,
    // description: false,
    // price: false,
    // stock: false
  };
  const validFormOption = {
    label: false,
    // description: false,
    // type: false,
    option_value: [validFormOptionValue],
  };
  const validFormProd = {
    label: false,
    // description: false,
    init_price: false,
    type: false,
    qty: false,
    options: [validFormOption],
  };
  const [product, setProduct] = useState<Product>(productData);
  const [showOptionProduct, setShowOptionProduct] = useState(false);
  const [showQuantityInput, setShowQuantityInput] = useState(false);
  const [showQuantityInputExra, setShowQuantityInputExra] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const profile = useSelector((state: any) => state.profile);
  const [formValidation, setFormValidation] = useState<any>(validFormProd);
  const [showModalSuccess, setShowModalSucess] = useState(false);
  const [showExtraButton, setShowExtra] = useState(true);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [initFormValidation, setInitiFormValidation] = useState(false);
  const [isOpenProduct, setIsOpenProduct] = useState(showModalFormProduct);
  const productToggle = () => setIsOpenProduct(!isOpenProduct);
  useEffect(() => {
    setShowQuantityInput(false);
    initForm();
  }, [showModalFormProduct]);
  const initForm = () => {
    setShowExtra(true);

    if (type === "product") {
      setShowExtra(false);
      setFormValidation(validFormProd);
      // setProduct(productData)
    }
    if (type === "event" || type === "delivery") {
      const tab: Options[] = product.options;
      tab.push(optionData);
      product["options"] = tab;
      product.options[0].option_values.push(option_value);
      // product.options[0].option_values.push(option_value)

      setProduct({ ...product });

      formValidation.options[0].option_value.push(validFormOptionValue);
    }
  };

  const pushListOption = (index: any) => {
    const tab: Options[] = product.options;
    if (showExtraButton && type === "event") {
      tab.push(optionDataExtra);
    } else {
      tab.push(optionData);
    }
    product["options"] = tab;
    product.options[index].option_values.push(option_value);
    setProduct({ ...product });
    formValidation.options.push(validFormOption);

    // setInitiFormValidation(false)
    // if (product.options.length > 1) {
    //     formValidation.options.push(validFormOption)
    // }
  };
  const pushList_Option_value = (index: any) => {
    product.options[index].option_values.push(option_value);
    setProduct({ ...product });
    formValidation.options[index].option_value.push(validFormOptionValue);
  };
  const delete_Option = (index: any) => {
    const result = product.options.filter((element: any, indice: any) => {
      return indice != index;
    });
    product.options = result;
    setProduct({ ...product });
    const resultFormValid = formValidation.options.filter(
      (element: any, indice: any) => {
        return indice != index;
      }
    );
    formValidation.options = resultFormValid;
    setFormValidation({ ...formValidation });
  };
  const delete_option_value = (index_option: any, index_option_value: any) => {
    const option = product.options.find((element: any, indice: any) => {
      return indice == index_option;
    });
    console.log("option", option);
    if (option?.extra) {
      const result = option.option_values.filter(
        (element: any, indice: any) => {
          return indice != index_option_value;
        }
      );
      console.log("result :", result);
      if (result != undefined)
        if (result.length == 0) {
          delete_Option(index_option);
          setShowExtra(true);
        } else {
          product.options[index_option].option_values = result;
          setProduct({ ...product });
        }
    } else {
      if (option != undefined) {
        const result = option.option_values.filter(
          (element: any, indice: any) => {
            return indice != index_option_value;
          }
        );
        if (result != undefined)
          product.options[index_option].option_values = result;
        const resultOptionFormValid=formValidation.options[index_option]
        console.log("resultOptionForm",resultOptionFormValid)
        const resultOptionValueForm=resultOptionFormValid.option_value.filter(
          (element: any, indice: any) => {
            return indice != index_option_value;
          }
        );
        console.log('resultOptionValueForm :',resultOptionValueForm)
        formValidation.options[index_option]=resultOptionValueForm
        setProduct({ ...product });
        console.log("prod", product);
      }
    }
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const { name, value } = event.target;

    if (name == "label") product.options[index].label = value;

    if (name == "description") product.options[index].description = value;

    if (name == "type") {
      product.options[index].type = value;
    }
    setProduct({ ...product });
  };
  const handleOptionValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any,
    indice: any
  ) => {
    const { name, value } = event.target;

    if (name !== undefined && name !== "price" && name !== "stock") {
      product.options[index].option_values[indice][name] = value;
    }

    if (name === "price") {
      // Regular expression to validate the price format (up to 4 digits before and 3 digits after a comma or period)
      const regex = /^\d{0,4}([.,]\d{0,3})?$/;

      if (regex.test(value)) {
        // Normalize the value to use a period as the decimal separator
        const normalizedValue = value.replace(",", ".");
        const valuePrice = parseFloat(normalizedValue);

        if (!isNaN(valuePrice) && valuePrice >= 0.1 && valuePrice <= 9999.999) {
          product.options[index].option_values[indice][name] = valuePrice;
        } else {
          product.options[index].option_values[indice][name] = 0;
        }
      } else {
        product.options[index].option_values[indice][name] = 0;
      }
    }

    if (name === "stock") {
      const stockValue = parseInt(value, 10);

      if (isNaN(stockValue) || stockValue <= 0) {
        product.options[index].option_values[indice][name] = 0;
      } else {
        product.options[index].option_values[indice][name] = stockValue;
      }
    }

    setProduct({ ...product });
  };
  const handleImageUpload = async () => {
    const photo = "";
    try {
      if (photo !== null) {
        const formData = new FormData();
        formData.append("photo", photo);
      } else {
        alert("Please select a file before uploading.");
      }
    } catch (error) {
      alert("Error occurred while uploading the file.");
    }
  };

  const sendDataToParent = () => {
    // Invoke the callback function and pass data to the parent
    onSendData();
  };
  const addNewProduct = () => {
    product.type = type;
    product.picture = photoProduct;
    setProduct({ ...product });
    if (product.label === "") {
      formValidation.label = true;
    }
    if (type === "product") {
      if (product.init_price == 0) {
        formValidation.init_price = true;
      }

      if (product.qty == 0) {
        formValidation.qty = true;
      }
      if (product.label != "") {
        if (showOptionProduct) {
          product.options.map((element: any, index: any) => {
            if (element.label == "") {
              formValidation.options[index].label = true;
            }
            if (element.label != "") {
              element.option_values.map((value: any, indice: any) => {
                if (value.label == "") {
                  formValidation.options[index].option_value[indice].label =
                    true;
                }
              });
            }
          });
          setFormValidation({ ...formValidation });
        }
        const valid: any = [];
        let check = false;
        formValidation.options.map((element: any) => {
          console.log("element :", element);
          if (!element.label) {
            valid.push(true);
            element.option_value.map((val: any) => {
              console.log("val :", val);
              if (!val.label) {
                valid.push(true);
              } else {
                valid.push(false);
              }
            });
          } else {
            valid.push(false);
          }
        });
        if (valid.every((value: any) => value === true)) {
          check = true;
        } else {
          check = false;
        }
        console.log("valid :", valid);
        console.log("check :", check);
        if (
          (product.label != "" &&
            product.init_price != 0 &&
            product.qty != 0 &&
            !showOptionProduct &&
            product.options.length == 0) ||
          (product.label != "" &&
            product.init_price != 0 &&
            product.qty != 0 &&
            showOptionProduct &&
            check)
        ) {
          setShowLoader(true);
          createProduct(product).then((result: any) => {
            setShowLoader(false);
            sendDataToParent();
            toggle();
            setMessageSuccess("Les informations a été ajouté avec succés");
            setShowModalSucess(true);
            setPhotoproduct("");
            setShowExtra(true);
          });
        }
      }
    } else if (type === "event" || type === "delivery") {
      if (type === "event") {
        product.options[0].label = "Evénement";
      }
      if (type === "delivery") {
        product.options[0].label = "Livraison";
      }
      product.options[0].option_values.map((element: any, index: any) => {
        if (element.label == "") {
          formValidation.options[0].option_value[index].label = true;
        }
        if (element.price == 0) {
          formValidation.options[0].option_value[index].price = true;
        }
      });
      const valid: any = [];
      let check = false;
      formValidation.options[0].option_value.map((element: any) => {
        console.log("element :", element);
        if (!element.label) {
          valid.push(true);
        } else {
          valid.push(false);
        }
        if (!element.price) {
          valid.push(true);
        } else {
          valid.push(false);
        }
      });
      if (valid.every((value: any) => value === true)) {
        check = true;
      } else {
        check = false;
      }
      if (product.label != "" && check) {
        if (product.label != "") {
          createProduct(product).then((result) => {
            setShowLoader(false);
            sendDataToParent();
            toggle();
            setMessageSuccess("Les informations a été ajouté avec succés");
            setShowModalSucess(true);
            setPhotoproduct("");
          });
        }
      }
    }
  };

  return (
    <>
      <SuccessModal
        isOpen={showModalSuccess}
        message={messageSuccess}
        toogle={() => {
          setShowModalSucess(!showModalSuccess);
        }}
      ></SuccessModal>
      <LoadingModal isOpen={showLoader}></LoadingModal>
      <Modal
        isOpen={showModalFormProduct}
        toggle={toggle}
        style={{ maxWidth: "85%", marginLeft: "14%", marginTop: "3%" }}
        onClosed={() => {
          setProduct(productData);
          setPhotoproduct("");
        }}
      >
        <ModalHeader style={{ color: "#162A60" }}>
          <img
            src={
              type === "product"
                ? imageProduct
                : type === "event"
                ? imageEvent
                : imageDelivery
            }
            height={type === "delivery" ? 80 : 60}
            width={type === "delivery" ? 80 : 60}
          />{" "}
          Ajouter{" "}
          {type === "product"
            ? "Produit"
            : type === "event"
            ? "Evénement"
            : "Livraison"}{" "}
        </ModalHeader>
        <ModalBody>
          <div className="row" style={{ marginLeft: "0.5%" }}>
            <div
              style={{
                width: "15%",
                marginTop:
                  type === "product"
                    ? "-2.5%"
                    : type === "event"
                    ? "-1.5%"
                    : "0px",
              }}
            >
              <div style={{ marginTop: type === "product" ? "33%" : "15%" }}>
                {photoProduct !== null && photoProduct != "" && (
                  <img
                    src={photoProduct}
                    alt="upload image"
                    width={200}
                    height={200}
                    className="img-display-after"
                    style={{
                      marginLeft: "3%",
                      marginBottom: "-17%",
                      border: "1px #213A7D inset",
                      borderRadius: "10px",
                    }}
                  />
                )}
                {(photoProduct === null || photoProduct === "") && (
                  <>
                    <div>
                      <DropZoneHolder
                        name="picture"
                        handleUploadDocument={setPhotoproduct}
                        typeImage64={true}
                        size="37%"
                        width="205px"
                        image={true}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div style={{ width: "33%" }}>
              <FormGroup className="position-relative">
                <Label
                  check
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                  for="label"
                  className="d-block"
                >
                  {type === "product" || type === "delivery" ? (
                    <> Libellé</>
                  ) : (
                    <>Nom de l'événement</>
                  )}
                  <span style={{ color: "#D83A52", marginLeft: "10px" }}>
                    {" "}
                    *
                  </span>
                </Label>
                <div className="mt-2">
                  <Input
                    // value={product.label != "" ? product.label : ""}
                    id="label"
                    name="label"
                    type="text"
                    onChange={(e) => {
                      product.label = e.target.value;
                      setProduct({ ...product });
                      if (product.label === "" && product.label == "") {
                        formValidation.label = true;
                      } else {
                        formValidation.label = false;
                      }
                      setFormValidation({ ...formValidation });
                    }}
                    invalid={formValidation.label}
                    valid={!formValidation.label && product.label != ""}
                  />
                  <FormFeedback className="mt-2">
                    Ce champ est invalide
                  </FormFeedback>
                </div>
              </FormGroup>
              {type === "product" && (
                <>
                  <div className="d-flex">
                    <div style={{ width: "90%" }}>
                      <Label
                        check
                        style={{ fontWeight: "bold", marginTop: "10px" }}
                        for="bank_account"
                        className="d-block"
                      >
                        Prix Initiale
                        <span style={{ color: "#D83A52", marginLeft: "10px" }}>
                          {" "}
                          *
                        </span>
                      </Label>
                      <div className="mt-2">
                        <Input
                          id="price"
                          name="init_price"
                          type="text"
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/,/g, '.');

                            // Check if the value is valid
                            if (value.length > 8) {
                              // Trim to max length if input exceeds
                              value = value.slice(0, 8);
                            }

                            // Split the value into integer and decimal parts
                            const [integerPart, decimalPart] =
                              value.split(".");

                            // Validate that the integer part has no more than 4 digits
                            if (integerPart.length > 4) {
                              // Restrict integer part to 4 digits
                              value =
                                integerPart.slice(0, 4) +
                                (decimalPart
                                  ? "." + decimalPart
                                  : "");
                            }

                            // Update the input value
                            e.target.value = value;
                         
                            // Convert formatted value to a number for further processing
                            const numericValue = parseFloat(product.init_price);
                          
                            // Update state and validation based on the numeric value
                            if (!isNaN(numericValue) && numericValue >= 0.1 && numericValue <= 9999.999) {
                              setProduct({ ...product, init_price: numericValue });
                              setFormValidation({ ...formValidation, init_price: true });
                            } else {
                              setProduct({ ...product, init_price: 0 });
                              setFormValidation({ ...formValidation, init_price: false });
                            }
                          }}
                          
                          invalid={formValidation.init_price}
                          valid={
                            !formValidation.init_price &&
                            product.init_price != undefined &&
                            product.init_price >= 0.1
                          }
                          min={0.1}
                          // maxLength={8}
                        />
                        <FormFeedback className="mt-2">
                          Ce champ est invalide
                        </FormFeedback>
                      </div>
                    </div>
                    <div style={{ marginTop: "9%", marginLeft: "1%" }}>
                      <span>{profile && profile.currency_iso}</span>
                    </div>
                  </div>
                </>
              )}
              {/* add option */}
            </div>
            <div style={{ width: "52%" }}>
              <Label
                check
                style={{ fontWeight: "bold", marginTop: "10px" }}
                for="bank_account"
                className="d-block"
              >
                Description
                {/* <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span> */}
              </Label>
              <div className="mt-2">
                <Input
                  style={{ height: "140px" }}
                  name="description"
                  type="textarea"
                  onChange={(e) => {
                    product.description = e.target.value;
                    setProduct({ ...product });
                    if (
                      product.description == "" &&
                      product.description === ""
                    ) {
                      formValidation.description = true;
                      setFormValidation({ ...formValidation });
                    } else {
                      formValidation.description = false;
                      setFormValidation({ ...formValidation });
                    }
                  }}
                  // value={product ? product.description : ""}
                  // invalid={formValidation.description}
                  valid={product.description != ""}
                />
                <FormFeedback className="mt-2">
                  Ce champ est invalide
                </FormFeedback>
              </div>
            </div>
          </div>
          {type === "product" && (
            <>
              <div className="row mt-2" style={{ marginLeft: "15.5%" }}>
                <Label
                  check
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                  for="label"
                  className="d-block"
                >
                  Quantité
                  <span style={{ color: "#D83A52", marginLeft: "10px" }}>
                    {" "}
                    *
                  </span>
                </Label>
                <div className="mt-2" style={{ width: "135px" }}>
                  <Input
                    name="qty"
                    type="text"
                    invalid={formValidation.qty}
                    valid={product.qty != 0 && !formValidation.qty}
                    value={product.qty !== undefined ? product.qty : ""}
                    onChange={(e) => {
                      // Parse the input value as an integer
                      const value = parseInt(e.target.value, 10);

                      // Check if the parsed value is NaN (Not a Number) or less than or equal to 0
                      if (isNaN(value) || value <= 0) {
                        // If the value is invalid, set product.qty to 0 and formValidation.qty to true
                        // product.qty = 0;
                        formValidation.qty = true;
                      } else {
                        // If the value is valid, update product.qty and set formValidation.qty to false
                        product.qty = value;
                        formValidation.qty = false;
                      }

                      // Update state with the new values
                      setProduct({ ...product });
                      setFormValidation({ ...formValidation });
                    }}
                    // value={product.qty != undefined ? product.qty : ""}
                  />
                  <FormFeedback className="mt-2">
                    Ce champ est invalide
                  </FormFeedback>
                </div>
              </div>
            </>
          )}

          <div className="row" style={{ marginLeft: "15.5%", marginTop: "1%" }}>
            {product.options.map((option: any, index: any) => {
              return (
                <>
                  <div key={index} className="options">
                    {type === "product" && (
                      <>
                        <div className="row">
                          <div
                            style={{
                              width: "18%",
                              marginTop: index > 0 ? "2%" : "0px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                color: "#213A7D",
                                fontWeight: "700",
                                fontFamily: "Inter",
                              }}
                            >
                              {type == "product" ? (
                                <>Caractéristique {index + 1}</>
                              ) : type === "event" ? (
                                <>Ticket</>
                              ) : (
                                <>Livraison</>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <Label
                              check
                              style={{ fontWeight: "bold", marginTop: "10px" }}
                              for="bank_account"
                              className="d-block"
                            >
                              Libellé
                              <span
                                style={{ color: "#D83A52", marginLeft: "10px" }}
                              >
                                {" "}
                                *
                              </span>
                            </Label>
                            <div className="mt-2">
                              <Input
                                key={index}
                                // value={option.label != "" ? option.label : ""}
                                id="label"
                                name="label"
                                type="text"
                                onChange={(e) => {
                                  handleChange(e, index);
                                  formValidation.options[index].label = false;
                                  setFormValidation({ ...formValidation });
                                  if (option.label == "") {
                                    formValidation.options[index].label = true;
                                    setFormValidation({ ...formValidation });
                                  } else {
                                    formValidation.options[index].label = false;
                                    setFormValidation({ ...formValidation });
                                  }
                                  setFormValidation({ ...formValidation });
                                }}
                                invalid={formValidation.options[index].label}
                                valid={option.label != ""}
                              />
                              <FormFeedback className="mt-2">
                                Ce champ est invalide
                              </FormFeedback>
                            </div>
                          </div>
                          <div className="col-6">
                            <Label
                              check
                              style={{ fontWeight: "bold", marginTop: "10px" }}
                              for="bank_account"
                              className="d-block"
                            >
                              Description
                              {/* <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span> */}
                            </Label>
                            <div className="mt-2">
                              <Input
                                style={{ height: "43px" }}
                                key={index}
                                name="description"
                                type="textarea"
                                rows="1"
                                // value={option.description != "" ? option.description : ""}
                                onChange={(e) => {
                                  handleChange(e, index);
                                  // formValidation.options[index].description = false;
                                  // setFormValidation({ ...formValidation })
                                  // if (option.description == "") {
                                  //     formValidation.options[index].description = true; setFormValidation({ ...formValidation })
                                  // } else {
                                  //     formValidation.options[index].description = false; setFormValidation({ ...formValidation })
                                  // }
                                  // setFormValidation({ ...formValidation })
                                }}
                                // invalid={formValidation.options[index].description}
                                valid={option.description != ""}
                              />
                              <FormFeedback className="mt-2">
                                Ce champ est invalide
                              </FormFeedback>
                            </div>
                          </div>
                          {/* <div className="col-2">
                                                    <Label check style={{ fontWeight: "bold", marginTop: "10px", }} for="bank_account" className="d-block">
                                                        Type
                                                        <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>
                                                    </Label>
                                                    <div className="mt-2">
                                                        <Input
                                                            key={index}
                                                            name="type"
                                                            type="select"
                                                            onChange={(e) => {
                                                                handleChange(e, index);
                                                                formValidation.options[index].type = true;
                                                                setFormValidation({ ...formValidation })
                                                                if (option.type == "") {
                                                                    formValidation.options[index].type = true; setFormValidation({ ...formValidation })
                                                                } else {
                                                                    formValidation.options[index].type = false; setFormValidation({ ...formValidation })
                                                                }
                                                                setFormValidation({ ...formValidation })
                                                            }}
                                                            invalid={formValidation.options[index].type}
                                                            valid={!formValidation.options[index].type && option.type != "" && !initFormValidation}
                                                        >
                                                            <option>Selectionner</option>
                                                            {
                                                                listTypes.map((element: any) => {
                                                                    return (<><option value={element.value} selected={option.type === element.value ? true : false}
                                                                    >{element.label}</option></>)
                                                                })
                                                            }
                                                        </Input>
                                                        <FormFeedback className="mt-2">
                                                            veuillez selectionner un type
                                                        </FormFeedback>
                                                    </div>
                                                </div> */}
                          <div className="col-1">
                            <div
                              style={{ marginTop: "50%", cursor: "pointer" }}
                              onClick={() => {
                                delete_Option(index);
                              }}
                            >
                              <FaTrashAlt color="#B42C2C" size={20} />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {type === "event" && (
                      <>
                        <div
                          className="row"
                          style={{ marginTop: option.extra ? "0" : "-4%" }}
                        >
                          <div
                            style={{
                              color: "#162A60",
                              fontWeight: "700",
                              fontSize: "22px",
                            }}
                          >
                            {option.extra ? (
                              <>
                                <span>Extra</span>
                              </>
                            ) : (
                              <>
                                <span>Options des prix</span>
                              </>
                            )}
                          </div>
                        </div>
                        <FormGroup check style={{ marginTop: "1%" }}>
                          <Input
                            key={index}
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: "1%",
                            }}
                            name="quantity"
                            type="checkbox"
                            onChange={() => {
                              index === 0
                                ? setShowQuantityInput(!showQuantityInput)
                                : setShowQuantityInputExra(
                                    !showQuantityInputExra
                                  );
                            }}
                          />
                          <Label style={{ marginTop: "7px" }} check>
                            L'acheteur peut définir la quantité
                          </Label>
                        </FormGroup>
                      </>
                    )}
                    {option.option_values.map((element: any, indice: any) => {
                      return (
                        <>
                          <div key={indice} className="optionValue">
                            {indice === 0 && (
                              <>
                                {" "}
                                <div
                                  className="row mt-2"
                                  style={{ marginLeft: "1%" }}
                                >
                                  <div style={{ width: "6%" }}>
                                    <span
                                      style={{
                                        fontSize: "17px",
                                        color: "#125FB9",
                                        fontWeight: "700",
                                        fontFamily: "Inter",
                                      }}
                                    >
                                      Valeurs{" "}
                                    </span>
                                  </div>
                                  <div style={{ width: "94%" }}>
                                    <hr></hr>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="row" style={{ marginLeft: "4%" }}>
                              <div className="col-2">
                                <Label
                                  check
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                  }}
                                  for="bank_account"
                                  className="d-block"
                                >
                                  Libellé
                                  <span
                                    style={{
                                      color: "#D83A52",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </span>
                                </Label>
                                <div className="mt-2">
                                  <Input
                                    key={indice}
                                    name="label"
                                    type="text"
                                    onChange={(e) => {
                                      handleOptionValue(e, index, indice);
                                      if (element.label === "") {
                                        formValidation.options[
                                          index
                                        ].option_value[indice].label = true;
                                      } else {
                                        formValidation.options[
                                          index
                                        ].option_value[indice].label = false;
                                      }
                                      setFormValidation({ ...formValidation });
                                    }}
                                    invalid={
                                      formValidation.options[index]
                                        .option_value[indice].label
                                    }
                                    valid={
                                      !formValidation.options[index]
                                        .option_value[indice].label &&
                                      element.label != ""
                                    }
                                  />
                                  <FormFeedback className="mt-2">
                                    Ce champ est invalide
                                  </FormFeedback>
                                </div>
                              </div>
                              <div className="col-3">
                                <Label
                                  check
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                  }}
                                  for="bank_account"
                                  className="d-block"
                                >
                                  Description
                                  {/* <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span> */}
                                </Label>
                                <div className="mt-2">
                                  <Input
                                    key={indice}
                                    style={{ height: "43px" }}
                                    // value={element.description != "" ? element.description : ""}
                                    name="description"
                                    type="textarea"
                                    rows="1"
                                    onChange={(e) => {
                                      handleOptionValue(e, index, indice);
                                      if (element.description === "") {
                                        formValidation.options[
                                          index
                                        ].option_value[indice].description =
                                          true;
                                      } else {
                                        formValidation.options[
                                          index
                                        ].option_value[indice].description =
                                          false;
                                      }
                                      setFormValidation({ ...formValidation });
                                    }}
                                    // invalid={formValidation.options[index].option_value[indice].description}
                                    valid={element.description != ""}
                                  />
                                  <FormFeedback className="mt-2">
                                    Ce champ est invalide
                                  </FormFeedback>
                                </div>
                              </div>
                              <div className="col-2">
                                <Label
                                  check
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                  }}
                                  for="bank_account"
                                  className="d-block"
                                >
                                  Prix Supplémentaire
                                  {/* <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span> */}
                                </Label>
                                <div className="mt-2">
                                  
                                  <Input
                                    key={indice}
                                    name="price"
                                    type="text"
                                    maxLength={8}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      value = value.replace(/,/g, '.');

                                      // Check if the value is valid
                                      if (value.length > 8) {
                                        // Trim to max length if input exceeds
                                        value = value.slice(0, 8);
                                      }

                                      // Split the value into integer and decimal parts
                                      const [integerPart, decimalPart] =
                                        value.split(".");

                                      // Validate that the integer part has no more than 4 digits
                                      if (integerPart.length > 4) {
                                        // Restrict integer part to 4 digits
                                        value =
                                          integerPart.slice(0, 4) +
                                          (decimalPart
                                            ? "." + decimalPart
                                            : "");
                                      }

                                      // Update the input value
                                      e.target.value = value;

                                      handleOptionValue(e, index, indice);

                                      const elementPrice = parseFloat(
                                        product.options[index].option_values[
                                          indice
                                        ].price
                                      );

                                      if (
                                        isNaN(elementPrice) ||
                                        elementPrice < 0.1 ||
                                        elementPrice > 9999.999
                                      ) {
                                        formValidation.options[
                                          index
                                        ].option_value[indice].price = true;
                                      } else {
                                        formValidation.options[
                                          index
                                        ].option_value[indice].price = false;
                                      }

                                      setFormValidation({ ...formValidation });
                                 
                                    }}
                                    invalid={
                                      formValidation.options[index]
                                        .option_value[indice].price
                                    }
                                    valid={
                                      !formValidation.options[index]
                                        .option_value[indice].price &&
                                      element.price !== undefined &&
                                      element.price >= 0.1
                                    }
                                    min={0.1}
                                  />
                                  <FormFeedback className="mt-2">
                                    Ce champ est invalide
                                  </FormFeedback>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "3.5%",
                                  marginLeft: "-1.2%",
                                  width: "5%",
                                }}
                              >
                                <span> {profile && profile.currency_iso}</span>
                              </div>
                              {type === "event" &&
                                showQuantityInput &&
                                index === 0 && (
                                  <>
                                    <div style={{ width: "10%" }}>
                                      <Label
                                        check
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: "10px",
                                        }}
                                        for="bank_account"
                                        className="d-block"
                                      >
                                        Quantité
                                        {/* { (<> <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span></>)} */}
                                      </Label>
                                      <div className="mt-2">
                                        <Input
                                          value={
                                            element.stock != ""
                                              ? element.stock
                                              : 0
                                          }
                                          name="stock"
                                          type="text"
                                          onChange={(e) => {
                                            handleOptionValue(e, index, indice);

                                            // Parse the stock value as an integer and check if it's valid
                                            const stockValue = parseInt(
                                              e.target.value,
                                              10
                                            );

                                            // Check if stock is not a number or is less than or equal to 0
                                            if (
                                              isNaN(stockValue) ||
                                              stockValue <= 0
                                            ) {
                                              formValidation.options[
                                                index
                                              ].option_value[indice].stock =
                                                true;
                                            } else {
                                              formValidation.options[
                                                index
                                              ].option_value[indice].stock =
                                                false;
                                            }

                                            setFormValidation({
                                              ...formValidation,
                                            });
                                          }}
                                          invalid={
                                            formValidation.options[index]
                                              .option_value[indice].stock
                                          }
                                          valid={
                                            !formValidation.options[index]
                                              .option_value[indice].stock &&
                                            element.stock != 0
                                          }
                                        />
                                        <FormFeedback className="mt-2">
                                          Ce champ est invalide
                                        </FormFeedback>
                                      </div>
                                    </div>
                                  </>
                                )}
                              {type === "event" &&
                                showQuantityInputExra &&
                                index === 1 && (
                                  <>
                                    <div style={{ width: "10%" }}>
                                      <Label
                                        check
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: "10px",
                                        }}
                                        for="bank_account"
                                        className="d-block"
                                      >
                                        Quantité
                                        {/* <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span> */}
                                      </Label>
                                      <div className="mt-2">
                                        <Input
                                          value={
                                            element.stock != ""
                                              ? element.stock
                                              : 0
                                          }
                                          name="stock"
                                          type="text"
                                          onChange={(e) => {
                                            handleOptionValue(e, index, indice);
                                            if (element.stock == 0) {
                                              formValidation.options[
                                                index
                                              ].option_value[indice].stock =
                                                true;
                                            } else {
                                              formValidation.options[
                                                index
                                              ].option_value[indice].stock =
                                                false;
                                            }
                                            setFormValidation({
                                              ...formValidation,
                                            });
                                          }}
                                          invalid={
                                            formValidation.options[index]
                                              .option_value[indice].stock
                                          }
                                          valid={
                                            !formValidation.options[index]
                                              .option_value[indice].stock &&
                                            element.stock != 0 &&
                                            !initFormValidation
                                          }
                                        />
                                        <FormFeedback className="mt-2">
                                          Ce champ est invalide
                                        </FormFeedback>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {product.options[index].option_values.length >
                                1 &&
                                indice != 0 &&
                                !product.options[index].extra && (
                                  <>
                                    <div
                                      style={{
                                        width: "3%",
                                        marginTop: "3.5%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        delete_option_value(index, indice);
                                      }}
                                    >
                                      <FaTrashAlt color="#B42C2C" size={20} />
                                    </div>
                                  </>
                                )}
                              {product.options[index].extra && (
                                <>
                                  <div
                                    style={{
                                      width: "3%",
                                      marginTop: "3.5%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      delete_option_value(index, indice);
                                    }}
                                  >
                                    <FaTrashAlt color="#B42C2C" size={20} />
                                  </div>
                                </>
                              )}

                              {option.option_values.length - 1 === indice && (
                                <>
                                  <div
                                    style={{
                                      width: "3%",
                                      marginTop: "3.5%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      pushList_Option_value(index);
                                    }}
                                  >
                                    <TiPlus color="#213A7D" size={20} />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
            {type === "product" && (
              <>
                <div
                  style={{
                    width: "281px",
                    marginTop: "1%",
                    marginBottom: "12px",
                  }}
                >
                  <div
                    onClick={() => {
                      setShowOptionProduct(true);
                      pushListOption(product.options.length);
                    }}
                    className="mt-2"
                    style={{
                      backgroundColor: "rgba(83, 109, 254, 0.15)",
                      color: "rgb(83, 109, 254)",
                      padding: "0.375rem 1.25rem",
                      fontSize: "14px",
                      borderRadius: "999px",
                      cursor: "pointer",
                    }}
                  >
                    <span>
                      Ajouter une Caractéristique <FaPlus className="mx-2" />
                    </span>
                  </div>
                </div>
              </>
            )}

            {type === "event" && showExtraButton && (
              <>
                <div
                  style={{ width: "200px", marginTop: "2%" }}
                  onClick={() => {
                    setShowExtra(false);
                  }}
                >
                  <div
                    onClick={() => {
                      pushListOption(product.options.length);
                    }}
                    className="mt-2"
                    style={{
                      backgroundColor: "rgba(83, 109, 254, 0.15)",
                      color: "rgb(83, 109, 254)",
                      padding: "0.375rem 1.25rem",
                      fontSize: "14px",
                      borderRadius: "999px",
                      cursor: "pointer",
                    }}
                  >
                    <span>
                      Ajouter un extra <FaPlus className="mx-2" />
                    </span>
                  </div>
                </div>
              </>
            )}
            {/* {
                                (product.type === "product" && showInputEdit && mode === "edit") && (<>
                                    <Label check style={{ fontWeight: "bold", marginTop: "10px", }} for="label" className="d-block">
                                        Quantité
                                    </Label>
                                    <div className="mt-2" style={{ width: "95px" }}>
                                        <Input
                                             name="qty"
                                             type="text"
                                             invalid={formValidation.qty}
                                             valid={!formValidation.qty && product.qty != 0 && !initFormValidation }
                                             onChange={(e) => {
                                                 isNaN(parseInt(e.target.value)) ? product.qty=0 : product.qty = parseInt(e.target.value)
                                                 ; setProduct({ ...product }); console.log(product)
 
                                                 if (product.qty === 0) {
                                                     formValidation.qty = true
                                                 } else {
                                                     formValidation.qty = false
                                                 }
                                                 setFormValidation({ ...formValidation })
                                             }}
                                             value={product.qty!=undefined ? product.qty:""} />
                                    </div>
                                </>)
                            } */}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button
              style={{ background: "#213A7D" }}
              className="mx-3"
              onClick={() => {
                addNewProduct();
                console.log('"');
              }}
            >
              Valider
            </Button>
            {"  "}
            <Button
              color="secondary"
              onClick={() => {
                toggle();
                setProduct(productData);
                setShowExtra(true);
              }}
            >
              Annuler
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export { AddProduct };
