// permissionsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: [],
    reducers: {
        updatePermissions(state, action) {
            return action.payload;
        },
        
    },
},
);

export const { updatePermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;

export const assignedPermissions = state => state.permissions;