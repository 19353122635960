import React from 'react';

import { lazy } from 'react';

const Login = lazy(() => import('../pages/account/login/Login'));
const Signup = lazy(() => import('../pages/account/register/Register'));
const RecoverMyAccount = lazy(() => import('../pages/account/reset-password/RecoverMyAccount'));
const SetNewPassword = lazy(() => import('../pages/account/reset-password/SetNewPassword'));
const NotFound = lazy(() => import('../pages/misc/NotFound'));
const NotAuthorized = lazy(() => import('../pages/misc/NotAuthorized'));

const AuthRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forget-password',
    element: <RecoverMyAccount />,
  },
  {
    path: '/update-pass',
    element: <SetNewPassword />
  }
];

export default AuthRoutes;