export function formatDatetime(datetimeString: string): string {
  const datetime = new Date(datetimeString);

  const dateString = datetime.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const timeString = datetime.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${dateString} à ${timeString}`;
}
export function formatDate(date: Date) {
  // 01, 02, 03, ... 29, 30, 31
  const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  // 01, 02, 03, ... 10, 11, 12
  const MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  // 1970, 1971, ... 2015, 2016, ...
  const yyyy = date.getFullYear();

  // create the format you want
  return yyyy + "-" + MM + "-" + dd;
}
export function formatDateYearMonthDay(datetimeString: string): string {
  const datetime = new Date(datetimeString);

  const dateString = datetime.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return dateString;
}
export const months = [
  { key: "01", value: "Janvier" },
  { key: "02", value: "Février" },
  { key: "03", value: "Mars" },
  { key: "04", value: "Avril" },
  { key: "05", value: "Mai" },
  { key: "06", value: "Juin" },
  { key: "07", value: "Juillet" },
  { key: "08", value: "Août" },
  { key: "09", value: "Septembre" },
  { key: "10", value: "Octobre" },
  { key: "11", value: "Novembre" },
  { key: "12", value: "Décembre" },
];
export function convertFormat_balance(amount: any) {
  const numericValue = Number(amount);

  if (isNaN(numericValue)) {
    return "Invalid";
  }
  const numberString = numericValue.toFixed(3).toString();
  const parts = numberString.split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return parts.join(".");
}
export function convertStatus(status: any, language: any) {
  let stringStatus = "";
  if (status == "PROCESSED") {
    if (language == "fr") {
      stringStatus = "Traité";
    } else {
      stringStatus = "Processed";
    }
  } else if (status == "CHARGEBACK") {
    if (language == "fr") {
      stringStatus = "Reprise de paiement";
    } else {
      stringStatus = "Charge Back";
    }
  } else if (status == "REFUNDED") {
    if (language == "fr") {
      stringStatus = "Rembourser";
    } else {
      stringStatus = "Refund";
    }
  }
  return stringStatus;
}

export const isObjectEmpty = (obj: any) => {
  return Object.values(obj).every(
    (value) => value === undefined || value === ""
  );
};

export const hasAnyErrors = (obj: any) => {
  return Object.values(obj).some((error) => error !== "");
};

export const convertFormatSelectList = (list: any) => {
  const newFormat: any = [];
  list.map((element: any, index: any) => {
    newFormat.push({
      value: element.french.replace(" ", "_").toLowerCase(),
      label: element.french,
    });
  });
  return newFormat;
};
// export const convertFormattersPhoneNumber=(phone:any)=>{
//   console.log("phone numbers",phone)
// }
export const formatPhoneNumber = (value: any, length: any) => {
  const formatterNumber =
    "+" + value.substr(0, length) + " " + value.substring(length, value.lenght);
  return formatterNumber;
};

export const getListYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 5; i++) {
      years.push(currentYear - i);
  }
  return years;

};
export const weeks=[{"key":"1","value":"1"},{"key":"2","value":"2"},{"key":"3","value":"3"},{"key":"4","value":"4"}]