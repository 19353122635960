import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { TiInfoLargeOutline } from 'react-icons/ti';
import { BiInfoSquare } from 'react-icons/bi';
import { FaInfoCircle } from 'react-icons/fa';
import { BsInfoCircle } from 'react-icons/bs';

interface IconToExplainProps {
    popId: string;
    content: React.ReactNode;
    direction?: 'top' | 'bottom' | 'left' | 'right';
    popoverHeight?: string;
    popoverWidth?: string;
    clickable?: boolean;
    size?: any;
}

const IconToExplain: React.FC<IconToExplainProps> = ({
    popId,
    content,
    direction = 'bottom',
    popoverHeight = 'auto',
    popoverWidth = 'auto',
    clickable = false,
    size = 18
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    const handleMouseEnter = () => {
        if (!clickable) {
            setPopoverOpen(true);
        }
    };

    const handleMouseLeave = () => {
        if (!clickable) {
            setPopoverOpen(false);
        }
    };

    const handleIconClick = (event: React.MouseEvent) => {
        if (clickable) {
            event.preventDefault();
            event.stopPropagation();
            togglePopover();
        }
    };


    return (
        <div onClick={() => null}>
            <BsInfoCircle
                id={popId}
                size={size}
                color="black"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => null}
                style={{ cursor: clickable ? 'pointer' : 'default', }}
            />
            <Popover
                placement={direction}
                isOpen={popoverOpen}
                target={popId}
                toggle={togglePopover}
                style={{ height: popoverHeight, width: popoverWidth }}
                trigger="manual"
            >
                <PopoverBody>{content}</PopoverBody>
            </Popover>
        </div>
    );
};

export default IconToExplain;