import React from "react";
import PaymentCheckout from "../pages/gateway/PaymentCheckout";
import PaylinkCheckout from "../pages/gateway/PaylinkCheckout";
import QRcodeCheckout from "../pages/gateway/QRcodeCheckout";
import PaylinkCheckoutInfos from "../pages/gateway/PaylinkCheckoutInfos";
import RedirectConfirmPayment from "../pages/gateway/RedirectConfirmPayment";
import PaymentSuccess from "../pages/gateway/PaymentSuccess";
import PaymentFailure from "../pages/gateway/PaymentFailure";

const GatewayRoutes = [
  {
    path: "/gateway",
    element: null,
    children: [
      {
        path: "redirect_confirm_cb_payment",
        element: <RedirectConfirmPayment />,
      },
      {
        path: ":hash",
        element: <PaymentCheckout />,
      },
      {
        path: "paylinks/:hash/checkout",
        element: <PaylinkCheckout />,
      },
      {
        path: "qrcodes/:hash/checkout",
        element: <QRcodeCheckout />,
      },
      {
        path: "paylinks/:hash/checkout-infos",
        element: <PaylinkCheckoutInfos />,
      },
      {
        path: "success",
        element: <PaymentSuccess />,
      },
      {
        path: "failure",
        element: <PaymentFailure />,
      },
    ],
  },
];

export default GatewayRoutes;
