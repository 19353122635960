import React from "react";

import { lazy } from "react";
import { Navigate } from "react-router-dom";
import PaylinkDetails from "../pages/paylinks/PaylinkDetails";
import PaylinkCheckoutGenerate from "../pages/paylinks/PaylinkCheckoutGenerate";
import BankAccounts from "../pages/finance/BankAccounts";
import DashboardPage from "../pages/DashboardPage";
import { element } from "prop-types";
const Home = lazy(() => import("../pages/Home"));
const HomeSubAccount = lazy(() => import("../pages/HomeSubAccount"));
//const Home = lazy(() => import('@pages/Home'))
const Paylinks = lazy(() => import("../pages/paylinks/PaylinksCheckout"));
const Operations = lazy(() => import("../pages/activities/Operations"));
const TransactionCB = lazy(() => import("../pages/activities/TransactionCB"));
const Invoices = lazy(() => import("../pages/finance/Invoices"));
const Wires = lazy(() => import("../pages/finance/Wires"));
const AutomaticInvoices = lazy(() =>
  import("../pages/finance/AutomaticInvoices")
);
const QrcodeTPE = lazy(() => import("../pages/qrcodes/QRcodeTPE"));
const QrcodeDelivery = lazy(() => import("../pages/qrcodes/QRcodeDelivery"));
const QrcodeOnTable = lazy(() => import("../pages/qrcodes/QRcodeOnTable"));
const IntegrationsPage = lazy(() => import("../pages/IntegrationPage"));
const DeliverySpace = lazy(() => import("../pages/DeliverySpace"));
const NotFound = lazy(() => import("../pages/misc/NotFound"));
const Setting = lazy(() => import("../pages/settings/menuSettings"));
const MenuOperation = lazy(() => import("../pages/activities/MenuOperation"));
const MenuFinance = lazy(() => import("../pages/finance/MenuFinance"));
const PaylinkPermanent = lazy(() =>
  import("../pages/paylinks/PaylinkPermanent")
);
const NotificationPage = lazy(() => import("../pages/NotificationPage"));

const token = localStorage.getItem("token");

const DashRoutes = [
  {
    path: "/home",
    element: <HomeSubAccount />,
    isProtected: true,
  },
  {
    path: "/dashboard",
    element: <Home />,
    isProtected: true,
  },

 
  {
    path: "/paylinks-checkout",
    element: null,
    children: [
      {
        path: "",
        element: <Paylinks />,
      },
      {
        path: ":id/edit",
        element: <PaylinkCheckoutGenerate />,
      },
      {
        path: "generate",
        element: <PaylinkCheckoutGenerate />,
      },
    ],
  },
  {
    path: "/qrcodes",
    element: null,
    children: [
      {
        path: "tpe",
        element: <QrcodeTPE />,
      },
      {
        path: "delivery",
        element: <QrcodeDelivery />,
      },
      {
        path: "on-table",
        element: <QrcodeOnTable />,
      },
    ],
  },
  {
    path: "/for-developers",
    element: <IntegrationsPage />,
  },
  {
    path: "/notifications",
    element: <NotificationPage />,
  },
  {
    path: "/delivery-space",
    element: <DeliverySpace />,
  },
  {
    path: "",
    element: token ? (
      <Navigate to="/dashboard" />
    ) : (
      <Navigate to="/login" replace />
    ),
  },

  /* {
    path:'/',
    element: token ? navigate('/dashboard') : navigate('/login')
  }, */
  { path: "*", element: <NotFound /> },
  {
    path: "/settings",
    element: <Setting />,
  },
  {
    path: "/dash",
    element: <DashboardPage />,
  },
  {
    path: "/payments",
    element: <MenuOperation />,
  },
  {
    path: "/finances",
    element: <MenuFinance />,
  },
  {
    path: "/invoices",
    element: <Invoices />,
  },
  {
    path: "/paylinks-permanent",
    element: <PaylinkPermanent />,
  },
];

export default DashRoutes;
