
import React, { CSSProperties, useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Popover, PopoverBody, Progress, Row, Spinner, TabContent, TabPane, Table } from 'reactstrap';
import { FaArrowLeft, FaArrowRight, FaDesktop, FaDonate, FaInfoCircle, FaList, FaLock, FaMobile, FaPlus, FaSyncAlt, FaTrash, FaTrashAlt } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import IconToExplain from "../../components/ui/IconToExplain";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setCheckoutFormErrors, updatePayerInfos } from "../../redux/slices/paymentCheckoutSlice";
import { hasAnyErrors } from "../../utils/helper";


interface PayerContactFormProps {
    onLoadPayment: () => void;
}

const PayerContactForm: React.FC<PayerContactFormProps> = ({ onLoadPayment }) => {

    const payment_checkout = useSelector((state: any) => state.paymentCheckout);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handlePayerInfosChange = (event: any) => {
        const { name, value, checked } = event.target;
        const regexOnlyText = /^[a-zA-Z\s]+$/
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const phoneNumberRegex = /^\d{8,8}$/;
        const allowOnlyDigitsRegex = /^\d+$/;

        let key_name = "";
        let updatedValue = value;

        if (name === "payer-firstname") {
            key_name = "firstname"
            dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The fristname should not be empty' }))
            }
            else if (!regexOnlyText.test(value)) {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The firstname should not contain numbers or special chars' }))
            }

        }

        else if (name === "payer-lastname") {
            key_name = "lastname"
            dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The lastname should not be empty' }))
            }
            else if (!regexOnlyText.test(value)) {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The lastname should not contain numbers or special chars' }))
            }

        }

        else if (name === "payer-email") {
            key_name = "email"
            dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The email should not be empty' }))
            }
            else if (!emailRegex.test(value)) {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The email is not a valid' }))
            }
        }

        else if (name === "payer-phone") {
            key_name = "phone"

            updatedValue.replace(/[^0-9]/g, '')

            dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
            if (updatedValue == "") {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The phone should not be empty' }))
            }
            else if (!phoneNumberRegex.test(updatedValue)) {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The phone is not a valid' }))
            }
        }

        else if (name === "payer-address") {
            key_name = "address"
            dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: key_name, value: 'The address should not be empty' }))
            }
        }
        else {
            key_name = name.split('-')[1];
            dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));

            const thefield = payment_checkout.additionalFields.find((field: any) => field.label.replace(/\s+/g, "_") === key_name);
            if (thefield) {
                const { label: field_label, field_type, required: field_required, options: field_options } = thefield;

                if (field_type === "text" || field_type === "dropdownlist") {
                    if (field_required && value == "") {
                        dispatch(setCheckoutFormErrors({ key: key_name, value: `The ${field_label} should not be empty` }))
                    }
                } else if (field_type === "checkbox") {
                    const oldCheckedList = payment_checkout.payerInfos[key_name];
                    updatedValue = checked ? [...oldCheckedList, value] : oldCheckedList.filter((op: any) => op !== value);
                    if (field_required && updatedValue.length === 0) dispatch(setCheckoutFormErrors({ key: key_name, value: `The ${field_label} should not be empty` }))
                }
            }
        }
        dispatch(updatePayerInfos({ [key_name]: updatedValue }));
    };

    const carryOnToPayment = () => {

        const isAdditionalFieldNotFilled = payment_checkout.additionalFields.filter((field: any) => field.required).some((field: any) => {
            const key_name = field.label.replace(/\s+/g, "_");
            const value = payment_checkout.payerInfos[key_name];

            if (field.field_type === "text" || field.field_type === "dropdownlist") {
                return value === "";
            } else if (field.field_type === "checkbox") {
                return Array.isArray(value) && value.length === 0;
            }

            return false;
        });

        return (isAdditionalFieldNotFilled || ['firstname', 'lastname', 'email', 'phone'].some(field => payment_checkout.payerInfos[field] === "") || hasAnyErrors(payment_checkout.checkoutFormErrors) || payment_checkout.checkoutOrder.resultedAmount == 0)
    }

    return (
        <>
            <FormGroup className="">
                <Label for='payer-firstname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                    <span className='' style={{ marginRight: "5px" }}>First name</span>
                    <span className="mx-1" style={{ color: "red" }}>*</span>
                    {/* <IconToExplain popId="payer-firstname-pop" content="The payer firstname" direction="right" /> */}
                </Label>
                <Input
                    name="payer-firstname"
                    type="text"
                    className="payer-firstname"
                    placeholder='Enter your firstname'
                    value={payment_checkout.payerInfos.firstname}
                    style={{ width: "100%" }}
                    valid={payment_checkout.payerInfos.firstname && !payment_checkout.checkoutFormErrors.firstname}
                    invalid={payment_checkout.checkoutFormErrors.firstname}
                    onChange={handlePayerInfosChange}
                />

                <FormFeedback>
                    <TiWarningOutline color='#DF1B41' />
                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors.firstname}</span>
                </FormFeedback>
            </FormGroup>
            <FormGroup className="">
                <Label for='payer-lastname' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                    <span className='' style={{ marginRight: "5px" }}>Last name</span>
                    <span className="mx-1" style={{ color: "red" }}>*</span>
                </Label>
                <Input
                    name="payer-lastname"
                    type="text"
                    className="payer-lastname"
                    placeholder='Enter your lastname'
                    value={payment_checkout.payerInfos.lastname}
                    style={{ width: "100%" }}
                    valid={payment_checkout.payerInfos.lastname && !payment_checkout.checkoutFormErrors.lastname}
                    invalid={payment_checkout.checkoutFormErrors.lastname}
                    onChange={handlePayerInfosChange}
                />

                <FormFeedback>
                    <TiWarningOutline color='#DF1B41' />
                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors.lastname}</span>
                </FormFeedback>
            </FormGroup>
            <FormGroup className="">
                <Label for='payer-email' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                    <span className='' style={{ marginRight: "5px" }}>Email</span>
                    <span className="mx-1" style={{ color: "red" }}>*</span>
                </Label>
                <Input
                    name="payer-email"
                    type="text"
                    className="payer-email"
                    placeholder='Enter your email'
                    value={payment_checkout.payerInfos.email}
                    style={{ width: "100%" }}
                    valid={payment_checkout.payerInfos.email && !payment_checkout.checkoutFormErrors.email}
                    invalid={payment_checkout.checkoutFormErrors.email}
                    onChange={handlePayerInfosChange}
                />

                <FormFeedback>
                    <TiWarningOutline color='#DF1B41' />
                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors.email}</span>
                </FormFeedback>
            </FormGroup>
            <FormGroup className="">
                <Label for='payer-phone' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                    <span className='' style={{ marginRight: "5px" }}>Phone</span>
                    <span className="mx-1" style={{ color: "red" }}>*</span>
                </Label>
                <Input
                    name="payer-phone"
                    type="tel"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={8}
                    className="payer-phone"
                    placeholder='Enter your phone'
                    value={payment_checkout.payerInfos.phone}
                    style={{ width: "100%" }}
                    valid={payment_checkout.payerInfos.phone && !payment_checkout.checkoutFormErrors.phone}
                    invalid={payment_checkout.checkoutFormErrors.phone}
                    onChange={handlePayerInfosChange}
                />

                <FormFeedback>
                    <TiWarningOutline color='#DF1B41' />
                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors.phone}</span>
                </FormFeedback>
            </FormGroup>

            {payment_checkout.checkoutOrder.delivery &&
                < FormGroup className="" >
                    <Label for="payer-address" className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                        <span className='' style={{ marginRight: "5px", textTransform: "capitalize" }}>Address</span>
                        <span className="mx-1" style={{ color: "red" }}>*</span>
                        <IconToExplain popId={`payer-address-pop`} content="add the delivery address" direction="right" />
                    </Label>

                    <Input
                        name="payer-address"
                        type="textarea"
                        className="payer-address"
                        placeholder="Enter your address"
                        value={payment_checkout.payerInfos.address}
                        onChange={handlePayerInfosChange}
                        rows={2}
                        maxLength={100}
                        valid={payment_checkout.payerInfos.address && !payment_checkout.checkoutFormErrors.address}
                        invalid={payment_checkout.checkoutFormErrors.address}
                    />

                    < FormFeedback FormFeedback >
                        <TiWarningOutline color='#DF1B41' />
                        <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors.address}</span>
                    </FormFeedback>
                </FormGroup>
            }

            {payment_checkout.additionalFields && payment_checkout.additionalFields.map((field: any, index: any) => {
                const key_name = field.label.replace(/\s+/g, "_");
                return (
                    < FormGroup className="" >
                        <Label for={`payer-${key_name}`} className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                            <span className='' style={{ marginRight: "5px", textTransform: "capitalize" }}>{field.label}</span>
                            {field.required && <span className="mx-1" style={{ color: "red" }}>*</span>}
                            <IconToExplain popId={`payer-${key_name}-pop`} content={field.description} direction="right" />
                        </Label>
                        {
                            field.field_type === "text" ?
                                <Input
                                    name={`payer-${key_name}`}
                                    type={field.characterLimits > 20 ? "textarea" : "text"}
                                    className={`payer-${key_name}`}
                                    placeholder={`Enter your ${field.label}`}
                                    value={payment_checkout.payerInfos[key_name]}
                                    rows={4}
                                    maxLength={field.characterLimits}
                                    valid={payment_checkout.payerInfos[key_name] && !payment_checkout.checkoutFormErrors[key_name]}
                                    invalid={payment_checkout.checkoutFormErrors[key_name]}
                                    onChange={handlePayerInfosChange}

                                />
                                : field.field_type === "checkbox" ?
                                    <Row>
                                        {field.options &&
                                            field.options.map((cb_option: any, index: any) =>
                                                <Col xs="auto">
                                                    <FormGroup
                                                        check
                                                        inline
                                                    >
                                                        <Input
                                                            name={`payer-${key_name}`}
                                                            style={{ cursor: "pointer" }}
                                                            type="checkbox"
                                                            value={cb_option}
                                                            className={`payer-${key_name}`}
                                                            onChange={handlePayerInfosChange}
                                                            //valid={!payment_checkout.checkoutFormErrors[key_name]}
                                                            invalid={payment_checkout.checkoutFormErrors[key_name]}
                                                        />
                                                        <Label check style={{ fontWeight: "bold", }}>
                                                            {cb_option}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    : field.field_type === "dropdownlist" ?
                                        <Row>
                                            {
                                                field.displayAsRadiobutton && field.options && field.options.map((cb_option: any, index: any) =>
                                                    <Col xs="auto">
                                                        <FormGroup check inline>
                                                            <Input
                                                                style={{ cursor: "pointer" }}
                                                                type="radio"
                                                                name={field.label}
                                                            />
                                                            <Label check style={{ fontWeight: "bold" }}>
                                                                {cb_option}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            {!field.displayAsRadiobutton && field.options &&
                                                <Input
                                                    name={`payer-${key_name}`}
                                                    type="select"
                                                    className='mx-2'
                                                    style={{ cursor: "pointer", width: "96%", color: "#697386" }}
                                                    onChange={handlePayerInfosChange}
                                                    valid={payment_checkout.payerInfos[key_name] && !payment_checkout.checkoutFormErrors[key_name]}
                                                    invalid={payment_checkout.checkoutFormErrors[key_name]}
                                                >
                                                    <option value="">{`Choose ${field.label}`}</option>
                                                    {
                                                        field.options.map((cb_option: any, index: any) => <option value={cb_option}>{cb_option}</option>)
                                                    }
                                                </Input>
                                            }
                                        </Row>
                                        : <></>
                        }
                        < FormFeedback FormFeedback >
                            <TiWarningOutline color='#DF1B41' />
                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors[key_name]}</span>
                        </FormFeedback>
                    </FormGroup>
                )

            })}
            <FormGroup className="">
                <Label for='payer-commentary' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                    <span className='' style={{ marginRight: "5px" }}>Commentary</span>
                    <IconToExplain popId="checkout-commentary-pop" content="you can leave here a comment to the merchat" direction="right" />
                </Label>
                <Input
                    name="payer-commentary"
                    type="textarea"
                    className="payer-commentary"
                    placeholder='Leave comment.'
                    value={payment_checkout.payerInfos.commentary}
                    valid={payment_checkout.payerInfos.commentary && !payment_checkout.checkoutFormErrors.commentary}
                    invalid={payment_checkout.checkoutFormErrors.commentary}
                    onChange={handlePayerInfosChange}
                    rows={4}
                />

                <FormFeedback>
                    <TiWarningOutline color='#DF1B41' />
                    <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{payment_checkout.checkoutFormErrors.commentary}</span>
                </FormFeedback>
            </FormGroup>
            <Button
                className="button-without-icon mx-2 my-5 float-start"
                onClick={onLoadPayment}
                disabled={carryOnToPayment()}
                style={{
                    borderRadius: "4px",
                    //width: "100%",
                }}
            >
                {payment_checkout.paylinkType == "donation" ? "Donate" : "Continuer vers le paiement"}
            </Button>
        </>
    )
}

export default PayerContactForm;