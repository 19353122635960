import { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState<string>('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            setToken(token);

            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp !== undefined && typeof decodedToken.exp === 'number') {
                if (decodedToken.exp < currentTime) {
                    setIsAuthenticated(false);
                } else {
                    setIsAuthenticated(true);
                }
            } else {
                setIsAuthenticated(false);
            }
        }
    }, []);

    const login = (token: string) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        setToken(token);
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('chosenCompany');
        setIsAuthenticated(false);
        setToken('');
    }

    return { isAuthenticated, token, login, logout };
};
