import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import './Pagination.css'
interface Props {
  onNextClick: () => void;
  onPrevClick: () => void;
  nextLabel: string;
  prevLabel: string;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
}

const Pagination = ({ onNextClick, onPrevClick, nextLabel, prevLabel, nextDisabled, prevDisabled }: Props) => {
  return (
    <ButtonGroup>
      <Button style={{height:"30px",paddingTop:"0px",paddingBottom:"0px",paddingRight:"5px",paddingLeft:"5px",borderRadius:"5px"}} onClick={onPrevClick} className='outlined-button mx-3' disabled={prevDisabled || false} >{prevLabel}</Button>
      <Button style={{height:"30px",paddingTop:"0px",paddingBottom:"0px",paddingRight:"5px",paddingLeft:"5px",borderRadius:"5px"}} onClick={onNextClick} className='outlined-button' disabled={nextDisabled || false}>{nextLabel}</Button>
    </ButtonGroup>
  );
};

export default Pagination;
