import axios from "axios";

export const BASE_URL_API = process.env.BACKEND_API_BASE_URL;

const basePanoramix = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_BASED_URL,
});
const baseAuth = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_BASED_URL_2,
});
basePanoramix.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const chosenCompany = localStorage.getItem("chosenCompany");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (chosenCompany) {
    config.headers["X-Chosen-Company"] = chosenCompany;
  }

  return config;
});

const baseGateway = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_BASED_URL_2 + "gateway/v2/",
});

export { basePanoramix, baseAuth, baseGateway };
